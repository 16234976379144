import React, { useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../utils/Header";
import MetaHelmet from "../metahelmet";
import Sizes from "./Sizes";

export default function Index() {
  const [metadata] = useState({
    title: "Cucufate MX | Tallas",
    description:
      "Cucufate es una marca 100% de moda española. Nace por la necesidad de encontrar moda infantil estilo español en Mexico, a precios razonables, con telas españolas, colores neutros y prendas especiales, todas hechas a mano por costureras mexicanas, aportando ese granito de arena contratando mamás de familias mexicanas de Baja California Sur.",
    image: "https://cucufate.s3.us-west-2.amazonaws.com/logo.png",
    url: "https://cucufate.mx/fabric",
  });

  return (
    <div className="catalogo">
      <MetaHelmet data={metadata}></MetaHelmet>
      <Header />
      <Sizes showPath={true} />
    </div>
  );
}
