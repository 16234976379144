export const DiscountOffer = ({colorText}) => {

    const promoStyles = {
        color: colorText === 'white' ? '#fff' : '#54a0ff',
        fontWeight:'normal',
        fontSize:'17px'
    }
    return (
        <span style={promoStyles}>
            DESCUENTO!!!
        </span>
    )
}
