import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Footer from "../components/utils/Footer";

import Home from "../components/home";
import About from "../components/about";
import Contact from "../components/contact";
import Sizes from "../components/sizes";
import Fabrics from "../components/fabrics";
import Ideas from "../components/ideas";
import Details from "../components/details";
import DetailsIdea from "../components/details-idea";
import Catalog from "../components/catalog";
import ShoppingCart from "../components/shopcart";
import Confirm from "../components/confirm";
import Wizard from "../components/wizard";

export default function DashboardRoutes() {
  return (
    <>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/sizes" component={Sizes} />
        <Route path="/fabrics" component={Fabrics} />
        <Route path="/ideas" component={Ideas} />
        <Route path="/idea" component={DetailsIdea} />
        <Route path="/producto" component={Details} />
        <Route path="/shop" component={Catalog} />
        <Route path="/cart/:id" component={ShoppingCart} />
        <Route path="/confirmacion" component={Confirm} />
        <Route path="/wizard" component={Wizard} />

        <Redirect to="/home" />
      </Switch>
      <Footer></Footer>
    </>
  );
}
