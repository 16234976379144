import axios from "axios";
import configs from "./../configs/config";

export const sendEmail = (data) => {
    return axios.post(`${configs.URL_API_BASE}/send-email`, data);
};

export const payOrder = (data) => {
    return axios.post(`${configs.URL_API_BASE}/pay`, data);
};

export const sendEmailNew = (data) => {
    return axios.post(`${configs.URL_API_BASE}/email`, data);
};
