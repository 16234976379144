import { types } from "../types";

const initialState = {
    ideas: [],
    selectedIdea: {},
};

export const ideasReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.getIdeas:
            return {
                ...state,
                ideas: payload,
            };
        case types.selectIdea:
            return {
                ...state,
                selectedIdea: payload,
            };
        case types.unselectIdea:
            return {
                ...state,
                selectedIdea: {},
            };
        default:
            return state;
    }
};
