import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDYpav1t9mkG6NBiQujKv8LWP9yhoj0o9U",
    authDomain: "cucufatemx.firebaseapp.com",
    projectId: "cucufatemx",
    storageBucket: "cucufatemx.appspot.com",
    messagingSenderId: "608289359330",
    appId: "1:608289359330:web:0ac89881d7391fb6b765bb",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { firebase, db };
