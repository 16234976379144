import { types } from "../types/index";

const initialState = {
    configs: {
        emails: "",
        generalDiscount: 0,
    },
    configsMessage: "",
};

export const configsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.getConfigs:
            return {
                ...state,
                configs: action.payload,
            };
        default:
            return state;
    }
};
