import { firebase } from "../../firebase";
import { types } from "../types";

import { finishLoading, startLoading } from "./ui";

export const startLoginWithEmailAndPassword = (email, password) => {
  return (dispatch) => {
    dispatch(startLoading());
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        const { uid, email } = user;
        dispatch(login(uid, email));
      })
      .catch((error) => {
        dispatch(finishLoading());
      });
  };
};

export const startLogout = () => {
  return async (dispatch) => {
    await firebase.auth().signOut();
    dispatch(logout());
  };
};

export const login = (uid, email) => {
  return {
    type: types.login,
    payload: { uid, email },
  };
};

export const logout = () => {
  return {
    type: types.logout,
  };
};
