import { useState } from "react";

export const useForm = (initialState = {}) => {
    const [values, setValues] = useState(initialState);

    const reset = (newState = initialState) => {
        setValues(newState);
    };

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value,
        });
    };

    const handleSelectChange = (selectedOption, inputName) => {
        setValues({
            ...values,
            [inputName]: selectedOption,
        });
    };

    return [values, handleInputChange, handleSelectChange, reset, setValues];
};
