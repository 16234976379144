import { types } from "../types";

const initialState = {
    messageEmailSended: "",
};

export const contactReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.contactSendEmail:
            return {
                ...state,
                messageEmailSended: payload,
            };
        default:
            return state;
    }
};
