import React from "react";
import { Helmet } from "react-helmet";

export default function MetaHelmet(props) {
    const { data } = props;

    return (
        <Helmet>
            <title>{data.title}</title>
            <meta name="description" content={data.description} />
            {/**    METADATA DE OPEN GRAPH */}
            <meta property="og:title" content={data.title} />
            <meta property="og:description" content={data.description} />
            <meta property="og:image" content={data.image} />
            <meta property="og:url" content={data.url} />
            <meta property="og:site-name" content={data.site_name} />
            {/*<meta property="og:type" content={data.type} />*/}
            {/**    METADATA DE TWITTER */}
            <meta name="twitter:title" content={data.title} />
            <meta name="twitter:description" content={data.description} />
            <meta name="twitter:image" content={data.image} />
        </Helmet>
    );
}
