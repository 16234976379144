
export const getDocumentsFromSnapshot = (snapshot) => {
    const documents = [];
    snapshot.forEach((document) => {
        documents.push({
            ...document.data(),
            id: document.id,
            key: document.id,
        })
    });
    return documents;
}