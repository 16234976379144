import { types } from "../types"

const initialState = {
    products: [],
    selectedProduct: {},
}

export const productsReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch(type){
        case types.getProducts:
            return {
                ...state,
                products: payload
            }
        case types.selectProduct:
            return {
                ...state,
                selectedProduct: payload
            }
        case types.unselectProduct:
            return {
                ...state,
                selectedProduct: {}
            }
        default:
            return state
    }
}