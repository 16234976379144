import React from "react";
import { useLoading, ThreeDots } from "@agney/react-loading";

export default function SesionLoading() {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <ThreeDots width="100" />,
    });

    return (
        <div className="loading">
            <h1>Espere un momento</h1>
            <h2>Validando sesion...</h2>
            <br />
            <section {...containerProps}>{indicatorEl}</section>
        </div>
    );
}
