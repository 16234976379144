/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../utils/Header";
import styles from "./Ideas.module.scss";
import MetaHelmet from "../metahelmet";
import { NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { startGetIdeas } from "../../redux/actions/ideas";
import ItemIdea from "../utils/ItemIdea";
import { startGetFabrics } from "../../redux/actions/wizard";

export default function Index() {
    const dispatch = useDispatch();
    const { ideas } = useSelector((state) => state.ideas);
    const { telas } = useSelector((state) => state.wizard);

    const [metadata] = useState({
        title: "Cucufate MX | Telas",
        description:
            "Cucufate es una marca 100% de moda española. Nace por la necesidad de encontrar moda infantil estilo español en Mexico, a precios razonables, con telas españolas, colores neutros y prendas especiales, todas hechas a mano por costureras mexicanas, aportando ese granito de arena contratando mamás de familias mexicanas de Baja California Sur.",
        image: "https://cucufate.s3.us-west-2.amazonaws.com/logo.png",
        url: "https://cucufate.mx/fabric",
    });

    const [categories] = useState(["Camisa", "Cubrepañal", "Capota", "Ranita", "Peto", "Short", "Falda", "Pelele", "Vestidos", "Abrigo", "Moño", "Fajin", "Portachupon", "Dona", "Pajes"]);
    const [catSelected, setCatSelected] = useState("");
    const [fabSelected, setFabSelected] = useState("");
    const [displayedIdeas, setDisplayedIdeas] = useState([]);

    useEffect(() => {
        dispatch(startGetIdeas());
        dispatch(startGetFabrics());
    }, []);

    useEffect(() => {
        getIdeasToDisplay();
    }, [ideas, catSelected, fabSelected]);

    const getIdeasToDisplay = () => {
        let _displayedIdeas = [...ideas];
        if (catSelected !== "") {
            _displayedIdeas = _displayedIdeas.filter((_idea) => _idea.Categoria === catSelected);
        }
        if (fabSelected !== "") {
            _displayedIdeas = _displayedIdeas.filter((_idea) => _idea.Tela.replace(/_/g, " ") === fabSelected);
        }
        setDisplayedIdeas(_displayedIdeas);
    };

    const handleSelectChange = ({ target: { name, value } }) => {
        switch (name) {
            case "categoria":
                setCatSelected(value);
                break;
            case "tela":
                setFabSelected(value);
                break;
            default:
                console.log("Select name not allowed.");
                break;
        }
    };

    return (
        <div className="catalogo">
            <MetaHelmet data={metadata}></MetaHelmet>
            <Header />
            <div className="container-fluid title-search">
                <div className="container">
                    <div className="row pt-5">
                        <nav className="col-8" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <NavLink to="/">
                                        <a>INICIO</a>
                                    </NavLink>
                                </li>
                                <li className="breadcrumb-item active">IDEAS</li>
                            </ol>
                        </nav>
                        <div className="col-12 col-md-8">
                            <h1>Algunas ideas para usted</h1>
                        </div>
                        <div className="col-12 col-sm-4 text-right">
                            <div className="row">
                                <div className="col-6">
                                    <select name="categoria" className="form-control" value={catSelected} onChange={handleSelectChange}>
                                        <option value="">Categorias</option>
                                        {categories.map((item, pos) => (
                                            <option key={pos} value={item}>
                                                {item.toUpperCase()}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-6">
                                    <select name="tela" className="form-control" value={fabSelected} onChange={handleSelectChange}>
                                        <option value="">Telas</option>
                                        {telas.map(({ name }, pos) => (
                                            <option key={pos} value={name}>
                                                {name.replace(/_/g, " ")}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.welcome + " ideas-container container animate__animated animate__fadeIn"}>
                <div className="row ">
                    <div className="col-12 col-md-12 spacing-mb-24 ">
                        <div className="row mx-0 px-0">
                            {displayedIdeas.length === 0 ? (
                                <h2 className={styles["text-center"]}>No se han encontrado ideas con el criterio seleccionado.</h2>
                            ) : (
                                displayedIdeas.map((product, index) => <ItemIdea key={product.ID} product={product} index={index} page="catalog-product" />)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
