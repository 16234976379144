/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWizard } from "react-use-wizard";

import Details from "../aditionals/Details";

import styles from "../steps/Steps.module.scss";
import { updateWizardData, updateWizardStepPosition } from "../../../redux/actions/wizard";
import { Button } from "../../utils/ui/Button";
import { validateName } from "../../../helpers/cleanParams";

const step2Images = require.context("../../../assets/step2", true);

export default function Step2({ executeScroll }) {
  const dispatch = useDispatch();
  const { step2Options, tipo, prenda } = useSelector((state) => state.wizard);

  const { handleStep, previousStep, nextStep } = useWizard();
  const [selectedOption, setSelectedOption] = useState(tipo);

  const [showMsg, setShowMsg] = useState(false);

  const handleSelectedOption = (item) => {
    setSelectedOption(item);
    dispatch(updateWizardData("tipo", item));
  };

  handleStep(() => {
    dispatch(updateWizardStepPosition(3));
  });

  const next = () => {
    if (tipo === "") {
      setShowMsg(true);
    } else {
      setShowMsg(false);
      executeScroll();
      nextStep();
    }
  };

  const returnStep = () => {
    dispatch(updateWizardData("tipo", ""));
    executeScroll();
    previousStep();
  };

  return (
    <>
      <h2>Paso 2: Seleccione una opción</h2>
      <hr />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="row">
              {step2Options[prenda].map((item, pos) => (
                <div className="col-6 col-sm-3 col-lg-2" key={pos}>
                  <figure className="figure item-container" onClick={() => handleSelectedOption(item)}>
                    {item === selectedOption && <i className="fas fa-check-circle"></i>}
                    <img
                      className={`img-thumbnail ${item === selectedOption ? styles.selected + " elevation-005" : null}`}
                      src={step2Images(`./${item}.png`)}
                      alt={item}
                    />
                    <figcaption className={`figure-caption spacing-mt-16 ${item === selectedOption ? "font-700" : null}`}>
                      {validateName(item.replace(/_/g, " "))}
                    </figcaption>
                  </figure>
                </div>
              ))}
            </div>
            {showMsg && (
              <div className="alert alert-warning" role="alert">
                Favor de seleccionar una opción sugerida.
              </div>
            )}
          </div>
          <div className="col-12 col-md-4">
            <Details />
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-start gap-3 ant-sig">
        <Button color="secondary" theme="outlined" onClick={() => returnStep()}>
          <i className="spacing-mr-8 fas fa-angle-double-left"></i> Anterior
        </Button>
        <Button color="secondary" onClick={() => next()}>
          Siguiente <i className="spacing-ml-8 fas fa-angle-double-right"></i>
        </Button>
      </div>
    </>
  );
}
