const configs = {
  //URL_API_BASE: "http://localhost:5000/api/v1",
  URL_API_BASE: "https://cucufate-api-promatic.herokuapp.com/api/v1",
  LOGO: "https://innversa.s3.us-west-2.amazonaws.com/logo.png",
  FOOTER_DESCRIPTION: "2023 Promatic Soft SC",
  BUSINESS_NAME: "Cucufate MX",
  FACEBOOK_URL: "https://www.facebook.com",
  TWITTER_URL: "https://twitter.com/home",
};

export default configs;
