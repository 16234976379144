const TYPES_ENUMS = {
  auth: "[AUTH]",
  ui: "[UI]",
  products: "[PRODUCTS]",
  cart: "[SHOPCART]",
  orders: "[ORDERS]",
  location: "[LOCATION_API]",
  configs: "[CONFIGS]",
  coupon: "[COUPON]",
  wizard: "[WIZARD]",
  contact: "[CONTACT]",
  ideas: "[IDEAS]",
  fabric_categories: "[FABRIC_CATEGORIES]",
};
const { auth, ui, configs, products, cart, orders, location, coupon, wizard, contact, ideas, fabric_categories } = TYPES_ENUMS;

export const types = {
  login: `${auth} Login`,
  logout: `${auth} Logout`,

  uiSetError: `${ui} setError`,
  uiRemoveError: `${ui} removeError`,
  uiStartLoading: `${ui} startLoading`,
  uiFinishLoading: `${ui} finishLoading`,
  uiShowModal: `${ui} showModal`,
  uiHideModal: `${ui} hideModal`,

  getConfigs: `${configs} get configs`,

  getProducts: `${products} getProducts`,
  selectProduct: `${products} selectProduct`,
  unselectProduct: `${products} unselectProduct`,

  getIdeas: `${ideas} getIdeas`,
  selectIdea: `${ideas} selectIdea`,
  unselectIdea: `${ideas} unselectIdea`,

  getCartItems: `${cart} get all cart items`,
  cartAddItem: `${cart} add Item in cart`,
  cartDeleteItem: `${cart} delete Item in cart`,
  getLinkItems: `${cart} get link items`,

  selectOrder: `${orders} selectOrder`,
  unselectOrder: `${orders} unselectOrder`,

  setLocationAuthToken: `${location} setLocationAuthToken`,
  getCountries: `${location} getCountries`,
  getStates: `${location} getStates`,
  getCities: `${location} getCities`,

  verifyCoupon: `${coupon} verify coupon in db`,
  updateCouponMessage: `${coupon} update coupon message`,

  updateStepData: `${wizard} update step data`,
  updateStepPosition: `${wizard} update step position`,
  resetWizardData: `${wizard} reset inital data in wizard`,

  contactSendEmail: `${contact} send email contact`,

  getFabricCategories: `${fabric_categories} getFabricCategories`,
  selectFabricCategory: `${fabric_categories} selectFabricCategory`,
};
