import React from "react";
import { useHistory } from "react-router-dom";

export default function ButtonWizard() {
  let history = useHistory();

  const showNewSection = (section) => {
    history.push(`/${section}`);
  };

  return (
    <div className="graphic-wizard-home">
      <div className="container">
        <div className="row graph-container">
          <div className="col-12 col-md-5 blusa-punteada">
            <img src="/assets/wizard-graph.png" alt="configura tu prenda" />
          </div>
          <div className="col-12 col-md-4 graphic-title">
            <h2>
              Diseña
              <br />
              <span>tu prenda</span>
            </h2>
          </div>
          <div className="col-12 col-md-3 graphic-title">
            <img src="/assets/cucufate_ropa-cut.gif" alt="animacion" />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="box-wizard-home">
              <img src="/assets/raton-wizard.png" alt="ratón cucufate" className="raton" />
              <div className="row">
                <div className="col-5">
                  <img src="/assets/wizard-camisa.png" alt="camisa" className="img-shake" />
                </div>
                <div className="col-7">
                  <p>Selecciona la prenda que más te guste</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="box-wizard-home">
              <img src="/assets/raton-wizard.png" alt="ratón cucufate" className="raton" />
              <div className="row">
                <div className="col-5">
                  <img src="/assets/wizard-cuello.png" alt="camisa" className="img-shake" />
                </div>
                <div className="col-7">
                  <p>Elige cuello, mangas y corte</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="box-wizard-home" onClick={() => showNewSection("fabrics")}>
              <img src="/assets/raton-wizard.png" alt="ratón cucufate" className="raton" />
              <div className="row">
                <div className="col-5">
                  <img src="/assets/wizard-tela.png" alt="camisa" className="img-shake" />
                </div>
                <div className="col-7">
                  <p>Selecciona tu tela favorita</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="box-wizard-home" onClick={() => showNewSection("sizes")}>
              <img src="/assets/raton-wizard.png" alt="ratón cucufate" className="raton" />
              <div className="row">
                <div className="col-5">
                  <img src="/assets/wizard-regla.png" alt="camisa" className="img-shake" />
                </div>
                <div className="col-7">
                  <p>Elige la talla amolde a tus medidas</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <h4 class="texto-informa">
              Importante: Los tiempos de entrega comenzarán a partir de la primera semana de mayo.
              <br />
              Por favor, comuníquese con Ichi para obtener más detalles.
            </h4>
          </div>
          <div className="col-12 text-center">
            <a href="/wizard">
              <button className="comienza">
                Comienza aquí <img src="/assets/wizard-flecha-boton.png" alt="flecha" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
