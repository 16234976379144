/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import { FloatingWhatsApp } from "react-floating-whatsapp-button";
import "react-floating-whatsapp-button/dist/index.css";

import "font-awesome/css/font-awesome.min.css";
import styles from "./Header.module.scss";

export default function Header() {
  const history = useHistory();

  const { cartProducts } = useSelector((state) => state.cart);
  const { fabricCategories } = useSelector((state) => state.fabricCategories);

  const [state, setState] = useState({
    products: [],
    menu: false,
  });

  const [show, setShow] = useState("");

  const [dropdownMenu, setDropdownMenu] = useState(false);

  useEffect(() => {
    setShow(state.menu ? "show" : "");
  }, [state.menu]);

  const handleGoToCart = () => {
    history.push("/cart/now");
    /*if (cartProducts.length === 0) {
      Swal.fire({
        title: "Mensaje",
        text: "De momento no tiene productos agregados al carrito.",
        confirmButtonText: '<i className="fa fa-thumbs-up"></i> Aceptar',
      });
    } else {
      history.push("/cart");
    }*/
  };
  const toggleMenu = () => {
    setState({ ...state, menu: !state.menu });
  };

  const backHome = () => {
    history.push("/home");
  };

  return (
    <Fragment>
      <nav className={styles.navbar + " navbar navbar-expand-lg navbar-light scrolling-navbar"}>
        <div className="container">
          <a
            className={styles.navbar_brand + " navbar-brand"}
            onClick={() => {
              backHome();
            }}>
            <img className={styles.logo} src="/logo-header.png" alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={styles.navbar_collapse + " collapse navbar-collapse " + show} id="navbarSupportedContent">
            <div className="row">
              <div className="col-12 col-md-12">
                <ul className={"primary-nav nav navbar-nav nav-flex-icons mr-3"}>
                  <li className={styles.nav_item + " text-center nav-item spacing-mr-20 "}>
                    <NavLink className={styles.link_menu} to="/">
                      <i className="fa fa-home"></i> INICIO
                    </NavLink>
                  </li>
                  <li className={styles.nav_item + " text-center nav-item spacing-mr-20"}>
                    <NavLink className={styles.link_menu} to="/about">
                      <i className="fa fa-users"></i> NOSOTRAS
                    </NavLink>
                  </li>
                  <li className={styles.nav_item + " text-center nav-item spacing-mr-20"}>
                    <NavLink className={styles.link_menu} to="/contact">
                      <i className="fa fa-envelope"></i> CONTACTENOS
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-12">
                <ul className={" secondary-nav nav navbar-nav nav-flex-icons mr-3"}>
                  <li className="text-center nav-item dropdown">
                    <a
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="/fabrics"
                      role="button"
                      aria-expanded="false"
                      onMouseEnter={() => setDropdownMenu(true)}>
                      TELAS A ELEGIR
                      {/* <span className="caret">&#8227;</span> */}
                    </a>
                    {dropdownMenu && (
                      <ul className="sub-nav" onMouseEnter={() => setDropdownMenu(true)} onMouseLeave={() => setDropdownMenu(false)}>
                        <li>
                          <NavLink className={styles.link_menu} to="/fabrics">
                            TODAS LAS TELAS
                          </NavLink>
                        </li>
                        {fabricCategories.map((item) => (
                          <li key={item.id}>
                            <NavLink className={styles.link_menu} to={`/fabrics?c=${item.id}`}>
                              {item.name.toUpperCase()}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                    {/*  */}
                  </li>

                  <li className={styles.nav_item + " text-center nav-item"}>
                    <NavLink className={styles.link_menu} to="/sizes">
                      GUIA DE TALLAS
                    </NavLink>
                  </li>
                  <li className={styles.nav_item + " text-center nav-item"}>
                    <NavLink className={styles.link_menu} to="/ideas">
                      IDEAS DE PRENDAS
                    </NavLink>
                  </li>
                  <li className={styles.nav_item + " text-center nav-item"}>
                    <NavLink className={styles.link_menu} to="/shop">
                      LISTAS PARA ENVIAR
                    </NavLink>
                  </li>
                  <li className={styles.nav_item + " text-center nav-item"}>
                    <NavLink className={styles.link_menu} to="/wizard">
                      ARMA TU PRENDA
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <ul className={styles.button_cart_ul + " cart-button"}>
              <li className={styles.button_cart_li + " nav-item"} onClick={handleGoToCart}>
                <div className={styles.button_cart_link + " nav-link border border-cart rounded cursor-pointer"}>
                  <i className="fa fa-shopping-cart mr-1"></i> Ver Carrito
                  {cartProducts.length >= 1 ? (
                    <span className="badge bg-danger spacing-ml-8">{cartProducts.length}</span>
                  ) : (
                    <span className="badge bg-secondary spacing-ml-8">0</span>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <FloatingWhatsApp
        popupMessage="Hola, ¿En qué podemos ayudarle?"
        phone="+526131251552"
        headerTitle="Cucufate MX Chat"
        zIndex={999999}
        autoOpenTimeout={60000}
      />
    </Fragment>
  );
}
