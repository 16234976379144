import React from "react";

export default function ItemRow(props) {
    const { item, index } = props;

    return (
        <tr>
            <td className="text-center">{index}</td>
            <td className="text-left">
                <p>{item.Categoria}</p>
            </td>
            <td className="text-left">
                <p>{item.Cant}</p>
            </td>
            <td className="text-left">${item.Descuento_Oferta === "-" ? item.Precio : item.Precio_Oferta}</td>
            <td>${item.Cant * (item.Descuento_Oferta === "-" ? item.Precio : item.Precio_Oferta)}</td>
        </tr>
    );
}
