/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import Header from "../utils/Header";
import "font-awesome/css/font-awesome.min.css";

import Swal from "sweetalert2";
import ItemRow from "./ItemRow";
import { useDispatch, useSelector } from "react-redux";
import { startGetSelectedOrderByID } from "../../redux/actions/orders";
import { Button } from "../utils/ui/Button";

export default function Index() {
  const dispatch = useDispatch();
  const { selectedOrder } = useSelector((state) => state.orders);

  const { coupon } = selectedOrder || {};
  const orderID = new URLSearchParams(window.location.search).get("order") || "";
  const [checking, setChecking] = useState(true);

  useEffect(() => {
    if (orderID && orderID.length > 0) {
      dispatch(startGetSelectedOrderByID(orderID));
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOrder === null) {
      Swal.fire({
        title: "Mensaje",
        text: "De momento no hay un orden confirmada.",
        confirmButtonText: '<i className"fa fa-thumbs-up"></i> Aceptar',
      }).then((e) => {
        window.location.href = "/";
      });
    }
    if (selectedOrder !== undefined) {
      setChecking(false);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder]);

  const handleBackToCat = () => {
    window.location.href = "/shop";
  };

  return (
    <div className="shopping-cart">
      <Header></Header>
      <div className="container main light-font animate__animated animate__fadeIn">
        {checking ? (
          <div className="row mt-4 mb-4">
            <div className="col-12 col-md-9 offset-md-2">
              <div className="row">
                <div className="">
                  <b>CARGANDO...</b>
                </div>
              </div>
            </div>
          </div>
        ) : (
          selectedOrder !== undefined && (
            <div className="row spacing-my-56">
              <div className="col-12 col-md-9 offset-md-2 text-center">
                <i className="spacing-mb-12 text-color-sucess fa fa-check-circle font-size-60"></i>
                <h1 className="spacing-mb-12 font-500 text-color-sucess">Gracias por su Orden</h1>
                <h3 className="spacing-mb-24 font-500 text-color-sucess">Estamos procesando su orden ahora...</h3>
              </div>
              <div className="col-12 col-md-9 offset-md-2 alert alert-warning text-xl-center" role="alert">
                Se ha enviado un correo electrónico, favor de revisar su bandeja de entrada. <br /> En caso de no visualizarlo por favor revise su
                bandeja de spam.
              </div>
              <div className="col-12 col-md-9 offset-md-2 alert alert-info text-xl-center" role="alert">
                Una vez que su paquete sea enviado se le notificará por correo electronico, de igual manera se puede contactar con nosotros en el
                siguiente enlace:
                <NavLink to="/contact">
                  <a className="nav-link">Deseo contactarme</a>
                </NavLink>
              </div>
              <div className="col-12  col-md-9 offset-md-2">
                <div className="row">
                  <div className="col-12 spacing-my-24">
                    <h2>Número de orden es: {selectedOrder.stripe_payment}</h2>
                  </div>
                  <div className="col-6">
                    <h5 className="">Detalles de Orden</h5>
                    <p className="pb-0 mb-0">
                      <b>Nombre:</b>
                    </p>
                    <p>{selectedOrder.customer_data.contactName}</p>
                    <p className="pb-0 mb-0">
                      <b>Correo:</b>
                    </p>
                    <p>{selectedOrder.customer_data.contactEmail}</p>
                    <p className="pb-0 mb-0">
                      <b>Domicilio:</b>
                    </p>
                    <p>{selectedOrder.customer_data.contactAddress}</p>
                  </div>
                  <div className="col-6">
                    <br />
                    <p className="pb-0 mb-0">
                      <b>Método de Envio:</b>
                    </p>
                    <p>Terrestre</p>
                    <p className="pb-0 mb-0">
                      <b>Método de Pago:</b>
                    </p>
                    <p>Tarjeta de Credito</p>
                    <p className="pb-0 mb-0">
                      <b>Estatus:</b>
                    </p>
                    <p>{selectedOrder.status}</p>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center flex-wrap spacing-mb-16">
                      <h3 className="my-0">Resumen de Pedido:</h3>
                      {coupon.validCoupon && (
                        <h5 className="text-success font-400 spacing-my-24 my-md-0">
                          Cupon Aplicado: {coupon.code} - {coupon.percent}% de descuento
                        </h5>
                      )}
                    </div>
                    <div className="spacing-mb-24">
                      {/*TABLE INIT*/}
                      <table className="table table-sm table-hover table-bordered">
                        <thead className="thead-dark text-left">
                          <tr>
                            <th className="text-primary-dark-2">#</th>
                            <th className="text-primary-dark-2">Categoria</th>
                            <th className="text-primary-dark-2">Cantidad</th>
                            <th className="text-primary-dark-2">Costo Unidad</th>
                            <th className="text-primary-dark-2">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedOrder.items.map((item, index) => (
                            <ItemRow key={index.toString()} item={item} index={index + 1} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="text-dark text-right">
                      <p className="heading-3">
                        Sub - Total: <strong className="font-size-24 text-primary-dark-2">{`$${selectedOrder.totals.subtotal}`}</strong>
                      </p>
                      <p className="heading-3">
                        Envio: <strong className="font-size-24 text-primary-dark-2">{`$${selectedOrder.totals.shipping}`}</strong>
                      </p>
                      {coupon.validCoupon && (
                        <p className="text-secondary heading-3">
                          Precio total regular sin descuento:
                          <span className="text-through font-400"> ${selectedOrder.totals.subtotal + selectedOrder.totals.shipping}</span>
                        </p>
                      )}
                      <p className={coupon.validCoupon ? "text-success heading-3" : "heading-3"}>
                        Total{coupon.validCoupon ? " con Descuento: " : ": "}
                        <strong
                          className={
                            coupon.validCoupon ? "font-size-36 text-success" : "font-size-36 text-primary-dark-2"
                          }>{`$${selectedOrder.totals.total}`}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <Button type="button" theme="outlined" onClick={handleBackToCat}>
                  {" "}
                  <i className="fa fa-backward spacing-mr-8" aria-hidden="true"></i> Seguir Comprando
                </Button>
                <br />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
