import { types } from "../types";

const initialState = {
    home: {
       
    },
};

export const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.getConfigs:
            return {
                ...state,
                configs: action.payload,
            };
        default:
            return state;
    }
};
