/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useWizard } from "react-use-wizard";
import Details from "../aditionals/Details";

import styles from "../steps/Steps.module.scss";

import { resetInitalData, startGetFabrics, updateWizardData, updateWizardStepPosition } from "../../../redux/actions/wizard";
import { Button } from "../../utils/ui/Button";

const step1Images = require.context("../../../assets/step1", true);

export default function Step1({ executeScroll }) {
  const dispatch = useDispatch();

  const { step1Options, prenda } = useSelector((state) => state.wizard);
  const { handleStep, nextStep } = useWizard();

  const [selectedOption, setSelectedOption] = useState(prenda);

  useEffect(() => {
    dispatch(resetInitalData());
    dispatch(startGetFabrics());
  }, []);

  const handleSelectedOption = (item) => {
    setSelectedOption(item);
    dispatch(updateWizardData("prenda", item));
  };

  handleStep(() => {
    dispatch(updateWizardStepPosition(2));
  });

  const _nextStep = () => {
    executeScroll();
    nextStep();
  };

  return (
    <>
      <h2>Paso 1: Seleccione su prenda</h2>
      <hr />
      <div className="container">
        <div className="row ">
          <div className="col-12 col-md-8">
            <div className="row">
              {step1Options.map((item, pos) => (
                <div className="col-6 col-sm-3 col-lg-2 cloth-container" key={pos}>
                  <figure className="figure item-container" onClick={() => handleSelectedOption(item?.value)}>
                    {item?.value === selectedOption && <i className="fas fa-check-circle"></i>}
                    <img
                      className={`img-thumbnail ${item?.value === selectedOption ? styles.selected + " elevation-01" : null}`}
                      src={step1Images(`./${item?.value}.png`)}
                      alt={item?.value}
                    />
                    <figcaption
                      className={`figure-caption title-text-cloth spacing-mt-16 ${item?.value === selectedOption ? "font-700" : null} fs-6`}>
                      {item?.value.replace(/_/g, " ")}
                    </figcaption>
                    <figcaption className={`figure-caption detail-text-cloth ${item?.value === selectedOption ? "font-700" : null} text-muted`}>
                      {item?.detail}
                    </figcaption>
                  </figure>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-md-4">
            <Details />
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-start gap-3 ant-sig">
        <Button color="secondary" onClick={() => _nextStep()} className="align-vertical">
          Siguiente <i className="spacing-ml-8 fas fa-angle-double-right"></i>
        </Button>
      </div>
    </>
  );
}
