import { db } from "../../firebase";
import { types } from "../types";

const col = "products";

/**
 * @param {String} order Si viene una string como 'high_cost' se hace el order por desc sino por asc.
 * @description Se mandan a llamar 4 productos que se van a mostrar en la Home Page.
 */
export const startGetHomeProducts = (order = null) => {
    return async (dispatch) => {
        let response = await db
            .collection(col)
            .where("archived", "==", false)
            .orderBy("Precio", order ? "desc" : "asc")
            .limit(4)
            .get();
        let productsParsed = [];
        response.forEach((document) => {
            productsParsed.push({
                ...document.data(),
                ID: document.id,
                KEY: document.id,
            });
        });
        dispatch(getProducts(productsParsed));
    };
};

/**
 * @param {String} slug Slug del producto.
 * @description Se manda a llamar el producto en Firebase con el slug y se coloca en el selectedProduct, sino se pone en null.
 */
export const startGetSelectedProductBySlug = (slug) => {
    return async (dispatch) => {
        let response = await db.collection(col).where("slug", "==", slug).get();
        if (response && response.size > 0) {
            let product;
            response.forEach((document) => {
                product = {
                    ...document.data(),
                    ID: document.id,
                };
            });
            dispatch(selectProduct(product));
        } else {
            dispatch(selectProduct(null));
        }
    };
};

/**
 * @param {String} id ID del documento de firebase del producto.
 * @description Se manda a llamar el producto en Firebase con el ID y se coloca en el selectedProduct, sino se pone en null.
 */
export const startGetSelectedProductByID = (id) => {
    return async (dispatch) => {
        let response = await db.collection(col).doc(id).get();
        if (response.exists) {
            dispatch(
                selectProduct({
                    ...response.data(),
                    ID: response.id,
                })
            );
        } else {
            dispatch(selectProduct(null));
        }
    };
};

export const startGetProductsByDefault = () => {
    return async (dispatch) => {
        let response = await db.collection(col).where("archived", "==", false).orderBy("Precio", "desc").get();
        let productsParsed = [];
        response.forEach((document) => {
            productsParsed.push({
                ...document.data(),
                ID: document.id,
                KEY: document.id,
            });
        });
        dispatch(getProducts(productsParsed));
    };
};

export const selectProduct = (product) => {
    return {
        type: types.selectProduct,
        payload: product,
    };
};

export const unselectProduct = () => {
    return {
        type: types.unselectProduct,
    };
};

const getProducts = (products) => {
    return {
        type: types.getProducts,
        payload: products,
    };
};
