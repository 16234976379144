import { db } from "../../firebase";
import { types } from "../types";
import { getDocumentsFromSnapshot } from "./_helpers";

const col = "fabric_categories";

export const startGetFabricCategories = () => {
    return async (dispatch) => {
        try {
            const snapshot = await db.collection(col).where("archived", "==", false).orderBy("name", "asc").get();
            const fabricCategories = getDocumentsFromSnapshot(snapshot);
            //console.log('llega', fabricCategories);
            dispatch({
                type: types.getFabricCategories,
                payload: fabricCategories,
            });
        } catch (error) {
            console.log(error);
        }
    };
};
