import validator from "validator";

export const validatorCartForm = ({
    contactName,
    contactEmail,
    contactPhone,
    contactCountry,
    contactCity,
    contactState,
    contactZipcode,
    contactAddress,
    cardName,
}) => {
    if (contactName.trim().length === 0) {
        return { correct: false, message: "El nombre de contacto es requerido." };
    }
    if (!validator.isEmail(contactEmail)) {
        return { correct: false, message: "El e-mail de contacto proporcionado no es correcto." };
    }
    if (contactPhone.trim().length === 0) {
        return { correct: false, message: "El número celular de contacto es requerido." };
    }
    if (!validator.isMobilePhone(contactPhone, "es-MX")) {
        return { correct: false, message: "El número celular debe de ser de almenos 10 caracteres." };
    }
    if (contactCountry.trim().length === 0) {
        return { correct: false, message: "El país del contacto es requerido." };
    }
    if (contactCity.trim().lenght === 0) {
        return { correct: false, message: "La ciudad del contacto es requerida." };
    }
    if (contactState.trim().length === 0) {
        return { correct: false, message: "El estado del contacto es requerido." };
    }
    if (contactZipcode.trim().length === 0) {
        return { correct: false, message: "El código postal del contacto es requerido." };
    }
    if (contactAddress.trim().length === 0) {
        return { correct: false, message: "La dirección del contacto es requerida." };
    }
    if (cardName.trim().length === 0) {
        return { correct: false, message: "El nombre de la tarjeta es requerido." };
    }

    return { correct: true, message: "" };
};
