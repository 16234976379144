import React from "react";
import "./ModalInfo.scss";

import { useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalInfo = ({ type, title, showModal, handleCloseModal }) => {
    const { policies } = useSelector((state) => state.ui);

    return (
        <Modal isOpen={showModal} toggle={handleCloseModal} size="lg">
            <ModalHeader toggle={handleCloseModal}>{title}</ModalHeader>
            <ModalBody>{policies[type]}</ModalBody>
            <ModalFooter>
                <button className="btn btn-success ml-auto" onClick={handleCloseModal}>
                    <i className="fa fa-location-arrow"></i> ACEPTAR
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalInfo;
