import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import ItemRow from "./ItemRow";

export default function TableCart() {
  const { cartProducts } = useSelector((state) => state.cart);

  return (
    <div>
      <table className="table table-sm table-hover table-bordered table-innshop">
        <thead className="thead-light">
          <tr>
            <th width="50">#</th>
            <th width="150">Foto</th>
            <th>Producto</th>
            <th>Cantidad</th>
            <th className="text-center">Costo Unitario</th>
            <th className="text-center">Total</th>
            <th width="100" className="text-right">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {cartProducts.map((item, index) => (
            <Fragment key={index}>
              <ItemRow item={item} index={index} />
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
