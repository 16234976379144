import { types } from "../types";

const getSizes = (all = true) => {
  let sizes = [
    {
      value: "0-12M",
      label: "RN",
    },
    {
      value: "0-12M",
      label: "0-3M",
    },
    {
      value: "0-12M",
      label: "3-6M",
    },
    {
      value: "0-12M",
      label: "6-9M",
    },
    {
      value: "0-12M",
      label: "9-12M",
    },
    {
      value: "12-36M",
      label: "12-18M",
    },
    {
      value: "12-36M",
      label: "18-24M",
    },
    {
      value: "12-36M",
      label: "24-36M",
    },
  ];

  if (all) {
    sizes.push({
      value: "4-5AÑOS",
      label: "4 AÑOS",
    });
    sizes.push({
      value: "4-5AÑOS",
      label: "5 AÑOS",
    });
    sizes.push({
      value: "6 AÑOS",
      label: "6 AÑOS",
    });
    sizes.push({
      value: "7 AÑOS",
      label: "7 AÑOS",
    });
    sizes.push({
      value: "8 AÑOS",
      label: "8 AÑOS",
    });
    sizes.push({
      value: "9 AÑOS",
      label: "9 AÑOS",
    });
  }

  return sizes;
};

const initialState = {
  stepPosition: 1,
  prenda: "CAMISA",
  tipo: "",
  cuello: "",
  manga: "",
  tela: "",
  talla: "",
  tallasTodas: getSizes(),
  tallaLabel: "",
  precio: 0,
  envio: 195,
  fabricCost: 0,
  detalles: "",
  notas: "",
  fruncido: "Sin_Fruncir",
  personalizado: "No_Personalizado",
  observaciones: "",
  telaSeleccionada: "",
  idTelaSeleccionada: "",
  actualStockTelaSelected: 0,

  telaSeleccionada2: "",
  idTelaSeleccionada2: "",
  actualStockTelaSelected2: 0,

  step1Options: [
    {
      value: "CAMISA",
      detail: "Desde 530 MXN",
    },
    {
      value: "CUBREPAÑAL",
      detail: "Desde 440 MXN",
    },
    {
      value: "CAPOTA",
      detail: "Desde 350 MXN",
    },
    {
      value: "RANITA",
      detail: "Desde 690 MXN",
    },
    {
      value: "PETO",
      detail: "Desde 690 MXN",
    },
    {
      value: "PETO_BRAGUITA",
      detail: "Desde 600 MXN",
    },
    {
      value: "SHORT",
      detail: "Desde 490 MXN",
    },
    {
      value: "PANTALON",
      detail: "Desde 690 MXN",
    },
    {
      value: "FALDA",
      detail: "Desde 490 MXN",
    },
    {
      value: "PELELE",
      detail: "Desde 640 MXN",
    },
    {
      value: "VESTIDOS",
      detail: "Desde 740 MXN",
    },
    {
      value: "ABRIGO",
      detail: "Desde 790 MXN",
    },
    {
      value: "MOÑO",
      detail: "Desde 90 MXN",
    },
    {
      value: "FAJIN",
      detail: "Desde 180 MXN",
    },
    {
      value: "PORTACHUPON",
      detail: "Desde 140 MXN",
    },
    {
      value: "DONA",
      detail: "Desde 90 MXN",
    },
  ],
  step2Options: {
    CAMISA: ["CAMISA_CORTE_A", "CAMISA_CORTE_PATO"],
    CUBREPAÑAL: ["CUBREPAÑAL_CON_OLANES", "CUBREPAÑAL_OLAN_TIRANTES", "CUBREPAÑAL_SIN_OLANES", "CUBREPAÑAL_SIN_OLAN_CON_TIRANTES"],
    CAPOTA: ["CAPOTA_CON_OLAN", "CAPOTA_SIN_OLAN"],
    RANITA: ["RANITA"],
    PETO: ["PETO_SIN_BRAGITA"],
    PETO_BRAGUITA: ["PETO_CON_BRAGUITA"],
    SHORT: ["SHORT_SIN_ELASTICO", "SHORT_SIN_ELASTICO_Y_TIRANTES", "SHORT_TIRANTES_UNIDOS"],
    PANTALON: ["PANTALON", "PANTALON_TIRANTES"],
    FALDA: ["FALDA_CON_TIRANTE", "FALDA_SIN_TIRANTE"],
    PELELE: ["PELELE_SIN_CORTE", "PELELE_CORTE_IMPERIO"],
    VESTIDOS: ["VESTIDOS_CORTE_A", "VESTIDOS_CORTE_IMPERIO", "VESTIDOS_CISNE", "VESTIDOS_OLAN_EN_PECHO", "VESTIDO_CRUZADO"],
    ABRIGO: ["ABRIGO"],
    MOÑO: ["MOÑO5X3", "MOÑO7X5", "MOÑO10X7"],
    FAJIN: ["FAJIN_CON_MOÑO", "FAJIN_SIN_MOÑO"],
    PORTACHUPON: ["PORTACHUPON"],
    DONA: ["DONA"],
  },
  step3Options: {
    CAMISA: true,
    CUBREPAÑAL: false,
    CAPOTA: false,
    RANITA: false,
    PETO: false,
    SHORT: false,
    PANTALON: false,
    FALDA: false,
    PELELE: true,
    VESTIDOS: true,
    ABRIGO: false,
    MOÑO: false,
    FAJIN: false,
    PORTACHUPON: false,
    DONA: false,
  },
  step3aOptions: {
    CAMISA_CORTE_A: ["CUELLO_BABY", "CUELLO_OLAN", "CUELLO_MAO", "CUELLO_SIMPLE"],
    CAMISA_CORTE_PATO: ["CUELLO_BABY", "CUELLO_OLAN", "CUELLO_SIMPLE"],
    PELELE_SIN_CORTE: ["CUELLO_BABY", "CUELLO_OLAN", "CUELLO_SIMPLE"],
    PELELE_CORTE_IMPERIO: ["CUELLO_BABY", "CUELLO_OLAN", "CUELLO_SIMPLE"],
    VESTIDOS_CORTE_A: ["CUELLO_BABY", "CUELLO_OLAN", "CUELLO_SIMPLE"],
    VESTIDOS_CORTE_IMPERIO: ["CUELLO_BABY", "CUELLO_OLAN", "CUELLO_SIMPLE"],
    VESTIDOS_CISNE: ["CUELLO_OLAN", "CUELLO_SIMPLE"],
    VESTIDOS_OLAN_EN_PECHO: ["CUELLO_SIMPLE"],
  },
  telas: [],
  tallas: {
    CAMISA: getSizes(),
    CUBREPAÑAL: getSizes(false),
    CAPOTA: getSizes(false),
    RANITA: getSizes(false),
    PETO: getSizes(),
    PETO_BRAGUITA: getSizes(false),
    SHORT: getSizes(),
    PANTALON: getSizes(),
    FALDA: getSizes(),
    PELELE: getSizes(false),
    VESTIDOS: getSizes(),
    ABRIGO: getSizes(),
    MOÑO: false,
    FAJIN: getSizes(),
    PORTACHUPON: false,
    DONA: false,
  },
  details: {
    CAMISA: (
      <>
        <p>
          Las camisas de cucufate, con estilo sencillo y elegante, sirven para combinarlas con todas las prendas Cucufate. ¡Es un must y lo pueden
          usar niños y niñas!
        </p>
        <p>
          Sus botones estan en la espalda, a excepción de la camisa cuello mao, que tiene 3 botones del cuello hasta el pecho, haciendo la prenda mas
          limpia y elegante en la parte delantera.{" "}
        </p>
        <p>Puedes seleccionar distintas modalidades para hacerla a tu gusto: seleccionando tipo de manga, tipo de cuello, etc. </p>
      </>
    ),
    CUBREPAÑAL: (
      <>
        <p>
          {" "}
          Nuestra prenda con la que empezamos. Un básico , unisex, en toda vida de bebes, perfectamnete combinable con cualquier camisa blanca que
          tengas en casa, o con camisas cucufate de la tela que quieras.{" "}
        </p>
        <p>
          Sus gomas elasticas en la cintura y piernas hacen que se ajusten a la perfección al cuerpecito del bebe, sin llegar a apretarle, ya que son
          cintas que se estiran y no aprietan. Pueden llegar a usarse hasta 2 tallas más arriba.
        </p>
        <p>Puedes añadir tirantes al cubrepañal para darle un toque mas personal a la prenda.</p>
      </>
    ),
    CAPOTA: (
      <>
        <p> Gorrito con o sin olan, perfecto para combinar tu look, y mantener la cabeza de tu bebe protegida. </p>
        <p>Elige la tela que mas te guste y combinala con tu prenda.</p>
      </>
    ),
    RANITA: (
      <>
        <p>
          Esta prenda “ranita” es ideal para niñas. Es una prenda ideal, muy parecida al peto braguita, con tirantes con olanes, dandole ese toque tan
          femenino.{" "}
        </p>
        <p>Su cintura es elastica, y sus muslos también, ajustandose a la talla del bebe sin apretarlo</p>
        <p>
          Tiene tirantes ajustables, con tres opciones de altura, dando la opcion a la mamá de elegir cuán apretado quiere que le quede al bebe la
          prenda, además de que le sirve para 1 o 2 tallas más dependiendo de lo rápido que crezca.
        </p>
        <p>
          Todas las ranitas tienen botones en la entrepierna, para hacer más facil el cambio de pañal de las más chiquitas, sin tener que desvestirle
          completamente.{" "}
        </p>
      </>
    ),
    PETO: (
      <>
        <p>¡El peto es una prenda ideal para vestir a los niños! Si el peto braguita te parece un poco de bebes, esta penda es ideal para tí.</p>
        <p>
          Tiene la cintura ajustada como los shorts, y los tirantes con tres ojales en la parte de la espalda, dando la opcion de seleccionar la
          altura que desees, asi como usar el peto 2-3 tallas más.{" "}
        </p>
      </>
    ),
    PETO_BRAGUITA: (
      <>
        <p>¡El peto es una prenda ideal para vestir a los niños! Si el peto braguita te parece un poco de bebes, esta penda es ideal para tí.</p>
        <p>
          Tiene la cintura ajustada como los shorts, y los tirantes con tres ojales en la parte de la espalda, dando la opcion de seleccionar la
          altura que desees, asi como usar el peto 2-3 tallas más.{" "}
        </p>
      </>
    ),
    SHORT: (
      <>
        {" "}
        <p>Pantalon corto, estilo español, corto de tiro, que favorece al maximo a los niños. </p>
        <p>Puedes añadirle tirantes para darle un toque mas elegante a la prenda.</p>
      </>
    ),
    PANTALON: (
      <>
        {" "}
        <p>Pantalon, estilo español, corto de tiro, que favorece al maximo a los niños. </p>
        <p>Puedes añadirle tirantes para darle un toque mas elegante a la prenda.</p>
      </>
    ),
    FALDA: (
      <>
        <p>
          ¡Mamá quiero vestir como tu!, Esta prenda, ideal para las niñas que empeizan a caminar, se ajusta perfectamente a la cintura, y puedes
          añadir tirantes para que sea más facil su sujección si son aun chiquititas.{" "}
        </p>
        <p>Además puedes combinarlo con un cubrepañal de la misma tela para evitar que se le vea la ropa interior. </p>
      </>
    ),
    PELELE: (
      <>
        <p>El pelele es una prenda completa que recubre toda la espalda y el pecho del bebe. </p>
        <p>Puedes añadir cuellos, mangas y cortes que desees (de todas las opciones que tiene Cucufate). </p>
        <p>
          Los peleles cuentan con botones en la espalda, y en la entrepierna, para ser más facil el cambio de pañal, sin tener que desvestir por
          completo al bebe.
        </p>
      </>
    ),
    VESTIDOS: (
      <>
        <p>Nuestros vestidos, super elegantes e ideales. </p>
        <p>
          Este vestido con Corte A, es amplio, ajustandose solo en la zona de los hombros, haciendo que las niñas puedan moverse con facilidad,
          siempre con un toque super femenino.{" "}
        </p>
        <p>Puedes seleccionar añadirle cuello y mangas a tu gusto, haciendolo más personalizado. </p>
        <p>
          Vestido con Corte Imperio, tiene un ligero fruncido en la zona del pecho, dando ese toque caracteristico de elegancia. Aunque no es
          apretado, se ajusta un cm más que el corte A haciendo que las niñas puedan moverse con facilidad,{" "}
        </p>
        <p>Puedes seleccionar añadirle cuello y mangas a tu gusto, haciendolo más personalizado. </p>
        <p>
          Vestido con olán en el pecho, tiene un ligero fruncido en la zona del pecho, dando ese toque caracteristico de elegancia, añadiendo unos
          olanes en la zona de pecho hasta el hombro. Aunque no es apretado, se ajusta un cm más que el corte A haciendo que las niñas puedan moverse
          con facilidad,
          <br />
          Puedes seleccionar añadirle cuello y mangas a tu gusto, haciendolo más personalizado.{" "}
        </p>
      </>
    ),
    ABRIGO: (
      <>
        <p>Nuestro abrigo, diseño TOP para climas otoñales. Selecciona la tela que mas te guste (panillas o viyelas) y crea tu prenda a tu gusto. </p>
        <p>Con tres botones en el frente, cuello baby y manga larga con olan.</p>
      </>
    ),
    MOÑO: <p>Escoge un tamaño y dale un toque especial con los moños combinados de Cucufate.</p>,
    FAJIN: <p>Escoge un tamaño y dale un toque especial con o sin moño de Cucufate.</p>,
    PORTACHUPON: (
      <p>
        El complemento perfecto para estar combinado a la ultima. No pierdas el chupón, y ten a un bebe elegante y cool con el portachupón de la misma
        tela.{" "}
      </p>
    ),
    DONA: (
      <p>
        Conjunta mama-hija con el coletero/dona de la misma tela, o bien, hazle a tu niña un coletero/dona que le combine a la perfección con su
        prenda seleccionada.{" "}
      </p>
    ),
  },
};

export const wizardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.updateStepPosition:
      return {
        ...state,
        stepPosition: payload,
      };
    case types.updateStepData:
      return {
        ...state,
        [payload.option]: payload.data,
      };
    case types.resetWizardData:
      return {
        ...state,
        prenda: "CAMISA",
        tipo: "",
        cuello: "",
        manga: "",
        tela: "",
        talla: "",
        precio: 0,
        fabricCost: 0,
      };
    default:
      return state;
  }
};
