/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import "font-awesome/css/font-awesome.min.css";

import Header from "../utils/Header";
import ItemCard from "../utils/ItemCart";
import CategorySelect from "./CategorySelect.js";

import { startGetProductsByDefault } from "../../redux/actions/products";

import styles from "./CategorySelect.module.scss";
import MetaHelmet from "../metahelmet";

export default function Index() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { products } = useSelector((state) => state.products);

    let [displayedProducts, setDisplayedProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categorySelected, setCategorySelected] = useState(null);
    const [sortSelect, setSortSelect] = useState("high_cost");
    const [searchString, setSearchString] = useState("");

    let productsWithInventory = products.filter((item) => item.Inventario !== "0");
    let productsWithoutInventory = products.filter((item) => item.Inventario === "0");
    let productsOrderedStock = productsWithInventory.concat(productsWithoutInventory);
    //console.log(productsOrderedStock);

    /* const handleSizeSelect = ({ value }) => {
        value === "Todas"
            ? setDisplayedProducts(productsOrderedStock)
            : setDisplayedProducts(productsOrderedStock.filter((item) => item.Talla === value));
    };*/
    const [metadata] = useState({
        title: "Cucufate MX | Catálogo  Digital",
        description: "CucufateMX, conoce nuestra gama de prendas ya diseñadas con un amplio surtido de colores y tallas.",
        image: "https://cucufate.s3.us-west-2.amazonaws.com/logo.png",
        url: "https://cucufate.mx/shop",
    });

    useEffect(() => {
        dispatch(startGetProductsByDefault());
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            displayProductsAndCategoriesByDefault();
        }
    }, [products]);

    useEffect(() => {
        setDisplayedProducts(sortDisplayedProductsByPrice(displayedProducts));
    }, [sortSelect]);

    useEffect(() => {
        filterDisplayedProducts();
    }, [categorySelected, searchString]);

    const displayProductsAndCategoriesByDefault = () => {
        let newCategoriesArray = [];
        productsOrderedStock.forEach((product) => {
            if (!newCategoriesArray.includes(product.Categoria)) {
                newCategoriesArray.push(product.Categoria);
            }
        });
        setDisplayedProducts(productsOrderedStock);
        setCategories(newCategoriesArray);
    };

    const sortDisplayedProductsByPrice = (productsList) => {
        switch (sortSelect) {
            case "high_cost":
                let newDisplayedProductsArrayByHigh = [...productsList].sort((a, b) => {
                    return (b.Precio_Oferta === "-" ? b.Precio : b.Precio_Oferta) - (a.Precio_Oferta === "-" ? a.Precio : a.Precio_Oferta);
                });
                let ProductsArrayByHighWithInventory = newDisplayedProductsArrayByHigh.filter((item) => item.Inventario !== "0");
                let ProductsArrayByHighWithoutInventory = newDisplayedProductsArrayByHigh.filter((item) => item.Inventario === "0");
                let ProductsArrayByHighOrganized = ProductsArrayByHighWithInventory.concat(ProductsArrayByHighWithoutInventory);
                return ProductsArrayByHighOrganized;
            case "low_cost":
                let newDisplayedProductsArrayByLow = [...productsList].sort((a, b) => {
                    return (a.Precio_Oferta === "-" ? a.Precio : a.Precio_Oferta) - (b.Precio_Oferta === "-" ? b.Precio : b.Precio_Oferta);
                });
                let ProductsArrayByLowWithInventory = newDisplayedProductsArrayByLow.filter((item) => item.Inventario !== "0");
                let ProductsArrayByLowWithoutInventory = newDisplayedProductsArrayByLow.filter((item) => item.Inventario === "0");
                let ProductsArrayByLowOrganized = ProductsArrayByLowWithInventory.concat(ProductsArrayByLowWithoutInventory);
                return ProductsArrayByLowOrganized;
            default:
                return productsList;
        }
    };

    const handleCategorySelect = async (category) => {
        setCategorySelected(category);
    };

    const filterDisplayedProducts = () => {
        if (searchString !== "" && categorySelected !== null) {
            // hay contenido en la barra buscadora y tiene una categoria seleccionada
            let newDisplayedProductsArray = [...productsOrderedStock].filter((product) => product.Categoria === categorySelected);
            newDisplayedProductsArray.filter((product) => product.Nombre_del_Producto.includes(searchString));
            setDisplayedProducts(sortDisplayedProductsByPrice(newDisplayedProductsArray));
        } else if (searchString === "" && categorySelected !== null) {
            // no hay contenido en la barra buscadora pero tiene una categoria seleccionada
            let newDisplayedProductsArray = [...productsOrderedStock].filter((product) => product.Categoria === categorySelected);
            setDisplayedProducts(sortDisplayedProductsByPrice(newDisplayedProductsArray));
        } else if (searchString !== "" && categorySelected === null) {
            // hay contenido en la barra buscadora pero no tiene una categoria seleccionada
            let newDisplayedProductsArray = [...productsOrderedStock].filter((product) => product.Nombre_del_Producto.includes(searchString));
            setDisplayedProducts(sortDisplayedProductsByPrice(newDisplayedProductsArray));
        } else {
            // no hay contenido en la barra buscadora y no hay categoria seleccionada
            setDisplayedProducts(sortDisplayedProductsByPrice(productsOrderedStock));
        }
    };

    const showWizard = () => {
        history.push("/wizard");
    };

    const selectionOrder = [
        {
            value: "high_cost",
            label: "PRECIO MAS ALTO",
        },
        {
            value: "low_cost",
            label: "PRECIO MAS BAJO",
        },
    ];

   /* const sizes = [
        {
            value: "Todas",
            label: "TALLAS",
        },
        {
            value: "RN",
            label: "RN",
        },
        {
            value: "0-3M",
            label: "0-3M",
        },
        {
            value: "3-6M",
            label: "3-6M",
        },
        {
            value: "6-9M",
            label: "6-9M",
        },
        {
            value: "9-12M",
            label: "9-12M",
        },
        {
            value: "12-18M",
            label: "12-18M",
        },
        {
            value: "18-24M",
            label: "18-24M",
        },
        {
            value: "24-36M",
            label: "24-36M",
        },
        {
            value: "4 AÑOS",
            label: "4 AÑOS",
        },
        {
            value: "5 AÑOS",
            label: "5 AÑOS",
        },
        {
            value: "6 AÑOS",
            label: "6 AÑOS",
        },
        {
            value: "7 AÑOS",
            label: "7 AÑOS",
        },
        {
            value: "8 AÑOS",
            label: "8 AÑOS",
        },
        {
            value: "9 AÑOS",
            label: "9 AÑOS",
        },
    ]; */

    return (
        <div className="catalogo">
            <MetaHelmet data={metadata}></MetaHelmet>
            <Header />
            <div className="container-fluid title-search">
                <div className="container">
                    <div className="row pb-4 pt-5">
                        <nav className="col-8" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <NavLink to="/">
                                        <a>INICIO</a>
                                    </NavLink>
                                </li>
                                <li className="breadcrumb-item active">CATALOGO</li>
                            </ol>
                        </nav>
                        <div className="col-12 col-md-8">
                            <h1>Catálogo Digital - Productos</h1>
                        </div>
                        <div className="col-12 col-sm-4 text-right">
                            <label className="sr-only" htmlFor="inlineFormInputGroup">
                                Buscador
                            </label>
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="fa fa-search"></i>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    ng-model="filter_products"
                                    id="inlineFormInputGroup"
                                    placeholder="Buscador"
                                    name="search"
                                    onChange={(e) => setSearchString(e.target.value)}
                                    value={searchString}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container main light-font">
                <div className="row">
                    <div className="col-12 col-md-8 spacing-mb-32">
                        <div className="button-wizard-catalogo">
                            <div className="container">
                                <div className="row">
                                    <div className="col-8 cel1">
                                        <img src="/assets/camiseta.png" alt="configura tu prenda" />
                                        <span>
                                            Diseña <b>tu prenda</b>
                                        </span>
                                    </div>
                                    <div className="col-4 cel3">
                                        <button onClick={showWizard}>
                                            clic aquí <img src="/assets/flecha-right.png" alt="configura tu prenda" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-4">
                        <div className="row d-flex justify-content-between">
                            <div className="col d-flex justify-content-end align-items-center">
                             { /*  <Select
                                    //placeholder={[sizeSelect]}
                                    defaultValue={sizes[0]}
                                    isSearchable={false}
                                    options={sizes}
                                    onChange={({ value }) => handleSizeSelect({ value })}
                                    required
                                />*/}
                                <div style={{ marginLeft: 20 }} className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                    <div className="btn-group" role="group">
                                        <Select
                                            defaultValue={selectionOrder[0]}
                                            isSearchable={false}
                                            options={selectionOrder}
                                            onChange={({ value }) => setSortSelect(value)}
                                        />
                                        {/*<select className="form-control form-control-sm" value={sortSelect} onChange={(e) => setSortSelect(e.target.value)}>
                                            <option value="high_cost">PRECIO MAS ALTO</option>
                                            <option value="low_cost">PRECIO MAS BAJO</option>
                                        </select>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex">
                    <div className="col-12 col-md-3 d-flex flex-column flex-column-reverse flex-md-column spacing-mb-32">
                        <div className="list-group">
                            <h3 className="spacing-mb-16">Categorías</h3>
                            <div className={`list-group-item list-group-item-action ${styles.list_group}`} onClick={() => setCategorySelected(null)}>
                                <FontAwesomeIcon icon={faTags} className="mr-1" />
                                Todos
                            </div>
                            {categories.map((category, index) => (
                                <CategorySelect handleCategorySelect={handleCategorySelect} category={category} key={index} />
                            ))}
                        </div>
                        <div className="publicidad my-4">
                            <h4 className="spacing-mb-16">Promoción</h4>
                            <div className="banner-vertical d-none d-md-inline">
                                <img
                                    className="img-fluid"
                                    src="https://cucufate.s3.us-west-2.amazonaws.com/cucufate-ads.jpg"
                                    alt="banner publicitario"
                                />
                            </div>
                            <div className="banner-vertical d-inline d-md-none">
                                <img
                                    className="img-fluid"
                                    src="https://cucufate.s3.us-west-2.amazonaws.com/cucufate-ads-900x350.jpg"
                                    alt="banner publicitario"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="container px-0">
                            <div className="row">
                                <div className="row mx-0 px-0">
                                    {displayedProducts.length > 0 ? (
                                        displayedProducts.map((product, index) => (
                                            <ItemCard key={product.ID} product={product} index={index} page="catalog-product" />
                                        ))
                                    ) : (
                                        <div class="alert alert-info" role="alert">
                                            Por el momento no hay productos con la talla seleccionada.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
