import { types } from "../types";
import Swal from "sweetalert2";

export const setError = (error) => {
  return {
    type: types.uiSetError,
    payload: error,
  };
};

export const removeError = () => {
  return {
    type: types.uiRemoveError,
  };
};

export const startLoading = () => {
  return {
    type: types.uiStartLoading,
  };
};

export const finishLoading = () => {
  return {
    type: types.uiFinishLoading,
  };
};

export const showModal = () => {
  return {
    type: types.uiShowModal,
  };
};

export const hideModal = () => {
  return {
    type: types.uiHideModal,
  };
};

export const showMessage = () => {
  return async () => {
    Swal.fire(
      "Estamos haciendo mantenimiento al sistema.",
      "Si requere de una cotización favor de ponerse en contacto directamente con Ichi.",
      "info"
    );
  };
};
