import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { firebase } from "../firebase";
import { login } from "../redux/actions/auth";
import { getAllProducts } from "../redux/actions/cart";

import DashboardRoutes from "./dashboardRoutes";
import PublicRoute from "./publicRoute";

import SesionLoading from "../components/utils/SesionLoading";
export default function AppRouter() {
    const dispatch = useDispatch();

    const [checking, setChecking] = useState(true);
    const [isLogged, setLogged] = useState(false);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user?.uid) {
                dispatch(login(user.uid, user.email));
                setLogged(true);
            } else {
                setLogged(false);
            }
            dispatch(getAllProducts());
            setChecking(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (checking) {
        //TO DO diseño visual de loader
        return <SesionLoading />;
    }

    return (
        <Router>
            <Switch>
                <PublicRoute path="/" isAuthenticated={isLogged} component={DashboardRoutes} />
            </Switch>
        </Router>
    );
}
