import { types } from "../types";
import { db } from "../../firebase/index";

const configsDocID = "cHJ9eDq3HHvn9fGekj0v";
const collection = "configs";

export const startGetConfigs = () => {
  return async (dispatch) => {
    const response = await db.collection(collection).doc(configsDocID).get();
    let configs = response.data();
    dispatch(getConfigs(configs));
  };
};

const getConfigs = (data) => {
  return {
    type: types.getConfigs,
    payload: data,
  };
};
