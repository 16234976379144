/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";

import configs from "../../configs/config";
import styles from "./footer.module.scss";

import ModalInfo from "../utils/ui/ModalInfo";
import { SYSTEM_LABELS } from "../../helpers/getStatisParams";

const Footer = () => {
  const [typeModal, setTypeModal] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [showModal, setShowModal] = useState(false);

  const showModalInfo = (type) => {
    if (type === "invoice") {
      setTypeModal("facturacion");
      setTitleModal("Facturación");
    } else {
      setTypeModal("reenvolso");
      setTitleModal("Politicas de Reembolso");
    }
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal(!showModal);
  };

  return (
    <footer className={styles.footer}>
      <div className="container-fluid spacing-mb-24 spacing-pt-32">
        <div className={styles.footer_soporte + " container"}>
          <div className={styles.footer_column}>
            <img className="img-fluid" width="150" src="/logo.png" alt={configs.BUSINESS_NAME} />
          </div>
          <div className={styles.footer_column}>
            <h5>Detalles de Contacto</h5>
            <ul>
              <li>Loreto, Baja California Sur</li>
              <li>Teléfono: 613 125 15 52</li>
            </ul>
          </div>
          <div className={styles.footer_column}>
            <h5>Soporte</h5>
            <ul>
              <li className={styles.policies_label} onClick={() => showModalInfo("")}>
                Políticas de Reembolso
              </li>
              {/*<li>Aviso de Privacidad</li>*/}
              <li className={styles.policies_label} onClick={() => showModalInfo("invoice")}>
                Facturación
              </li>
            </ul>
          </div>
          <div className={styles.footer_column}>
            <h5>Social Media</h5>
            <ul className={styles.icons}>
              <a href="https://www.facebook.com/cucufate_cucufate-108129217716383" target="_blank">
                <li>
                  <i className="fa-brands fa-facebook"></i>
                </li>
              </a>
              <a href="https://www.instagram.com/cucufate_cucufate/" target="_blank">
                <li>
                  <i className="fa-brands fa-instagram-square"></i>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.footer_copy_brand + " container-fluid spacing-pt-16"}>
        <div className={styles.footer_copy_brand_container + " container"}>
          <a href="https://www.promaticsoft.com/" target="_blank">
            {SYSTEM_LABELS.copyright} - {SYSTEM_LABELS.version}.
          </a>

          <div className={styles.footer_cards_brand}>
            <i className="fab fa-cc-visa"></i>
            <i className="fa-brands fa-cc-mastercard"></i>
            {/* <i className="fab fa-cc-paypal"></i> */}
          </div>
        </div>
      </div>
      <ModalInfo type={typeModal} title={titleModal} showModal={showModal} handleCloseModal={handleCloseModal} />
    </footer>
  );
};
export default Footer;
