const CAMISA_CORTE_A_CUELLO_BABY = require.context("../assets/step3/CAMISA_CORTE_A/CUELLO_BABY", true);
const CAMISA_CORTE_A_CUELLO_MAO = require.context("../assets/step3/CAMISA_CORTE_A/CUELLO_MAO", true);
const CAMISA_CORTE_A_CUELLO_OLAN = require.context("../assets/step3/CAMISA_CORTE_A/CUELLO_OLAN", true);
const CAMISA_CORTE_A_CUELLO_SIMPLE = require.context("../assets/step3/CAMISA_CORTE_A/CUELLO_SIMPLE", true);

const CAMISA_CORTE_PATO_CUELLO_BABY = require.context("../assets/step3/CAMISA_CORTE_PATO/CUELLO_BABY", true);
const CAMISA_CORTE_PATO_CUELLO_OLAN = require.context("../assets/step3/CAMISA_CORTE_PATO/CUELLO_OLAN", true);
const CAMISA_CORTE_PATO_CUELLO_SIMPLE = require.context("../assets/step3/CAMISA_CORTE_PATO/CUELLO_SIMPLE", true);

const PELELE_CORTE_IMPERIO_CUELLO_BABY = require.context("../assets/step3/PELELE_CORTE_IMPERIO/CUELLO_BABY", true);
const PELELE_CORTE_IMPERIO_CUELLO_OLAN = require.context("../assets/step3/PELELE_CORTE_IMPERIO/CUELLO_OLAN", true);
const PELELE_CORTE_IMPERIO_CUELLO_SIMPLE = require.context("../assets/step3/PELELE_CORTE_IMPERIO/CUELLO_SIMPLE", true);

const PELELE_SIN_CORTE_CUELLO_BABY = require.context("../assets/step3/PELELE_SIN_CORTE/CUELLO_BABY", true);
const PELELE_SIN_CORTE_CUELLO_OLAN = require.context("../assets/step3/PELELE_SIN_CORTE/CUELLO_OLAN", true);
const PELELE_SIN_CORTE_CUELLO_SIMPLE = require.context("../assets/step3/PELELE_SIN_CORTE/CUELLO_SIMPLE", true);

const VESTIDOS_CISNE_CUELLO_BABY = require.context("../assets/step3/VESTIDOS_CISNE/CUELLO_BABY", true);
const VESTIDOS_CISNE_CUELLO_OLAN = require.context("../assets/step3/VESTIDOS_CISNE/CUELLO_OLAN", true);
const VESTIDOS_CISNE_CUELLO_SIMPLE = require.context("../assets/step3/VESTIDOS_CISNE/CUELLO_SIMPLE", true);

const VESTIDOS_CORTE_A_CUELLO_BABY = require.context("../assets/step3/VESTIDOS_CORTE_A/CUELLO_BABY", true);
const VESTIDOS_CORTE_A_CUELLO_OLAN = require.context("../assets/step3/VESTIDOS_CORTE_A/CUELLO_OLAN", true);
const VESTIDOS_CORTE_A_CUELLO_SIMPLE = require.context("../assets/step3/VESTIDOS_CORTE_A/CUELLO_SIMPLE", true);

const VESTIDOS_CORTE_IMPERIO_CUELLO_BABY = require.context("../assets/step3/VESTIDOS_CORTE_IMPERIO/CUELLO_BABY", true);
const VESTIDOS_CORTE_IMPERIO_CUELLO_OLAN = require.context("../assets/step3/VESTIDOS_CORTE_IMPERIO/CUELLO_OLAN", true);
const VESTIDOS_CORTE_IMPERIO_CUELLO_SIMPLE = require.context("../assets/step3/VESTIDOS_CORTE_IMPERIO/CUELLO_SIMPLE", true);

const VESTIDOS_OLAN_EN_PECHO_CUELLO_BABY = require.context("../assets/step3/VESTIDOS_OLAN_EN_PECHO/CUELLO_BABY", true);
const VESTIDOS_OLAN_EN_PECHO_CUELLO_OLAN = require.context("../assets/step3/VESTIDOS_OLAN_EN_PECHO/CUELLO_OLAN", true);
const VESTIDOS_OLAN_EN_PECHO_CUELLO_SIMPLE = require.context("../assets/step3/VESTIDOS_OLAN_EN_PECHO/CUELLO_SIMPLE", true);

const OTROS = require.context("../assets/step2", true);
const SLEEVES = require.context("../assets/sleeves", true);

export const getImages = () => {
  return {
    CAMISA_CORTE_A_CUELLO_BABY,
    CAMISA_CORTE_A_CUELLO_MAO,
    CAMISA_CORTE_A_CUELLO_OLAN,
    CAMISA_CORTE_A_CUELLO_SIMPLE,
    CAMISA_CORTE_PATO_CUELLO_BABY,
    CAMISA_CORTE_PATO_CUELLO_OLAN,
    CAMISA_CORTE_PATO_CUELLO_SIMPLE,
    PELELE_CORTE_IMPERIO_CUELLO_BABY,
    PELELE_CORTE_IMPERIO_CUELLO_OLAN,
    PELELE_CORTE_IMPERIO_CUELLO_SIMPLE,
    PELELE_SIN_CORTE_CUELLO_BABY,
    PELELE_SIN_CORTE_CUELLO_OLAN,
    PELELE_SIN_CORTE_CUELLO_SIMPLE,
    VESTIDOS_CISNE_CUELLO_BABY,
    VESTIDOS_CISNE_CUELLO_OLAN,
    VESTIDOS_CISNE_CUELLO_SIMPLE,
    VESTIDOS_CORTE_A_CUELLO_BABY,
    VESTIDOS_CORTE_A_CUELLO_OLAN,
    VESTIDOS_CORTE_A_CUELLO_SIMPLE,
    VESTIDOS_CORTE_IMPERIO_CUELLO_BABY,
    VESTIDOS_CORTE_IMPERIO_CUELLO_OLAN,
    VESTIDOS_CORTE_IMPERIO_CUELLO_SIMPLE,
    VESTIDOS_OLAN_EN_PECHO_CUELLO_BABY,
    VESTIDOS_OLAN_EN_PECHO_CUELLO_OLAN,
    VESTIDOS_OLAN_EN_PECHO_CUELLO_SIMPLE,
    OTROS,
    SLEEVES,
  };
};
