import { types } from "../types";

const initialState = {
  cartProducts: [],
};

export const cartReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.cartAddItem:
      return {
        ...state,
        cartProducts: payload,
      };
    case types.cartDeleteItem:
      return {
        ...state,
        cartProducts: payload,
      };
    case types.getCartItems:
      return {
        ...state,
        cartProducts: payload,
      };
    case types.getLinkItems:
      return {
        ...state,
        cartProducts: payload,
      };
    default:
      return state;
  }
};
