/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Cardinfo } from "../utils/ui/Cardinfo";

import Header from "../utils/Header";
import styles from "./About.module.scss";
import MetaHelmet from "../metahelmet";
import { startGetConfigs } from "../../redux/actions/configs";
import { useDispatch, useSelector } from "react-redux";

export default function Index() {
    const dispatch = useDispatch();
    const { configs } = useSelector((state) => state.configs);

    const [metadata] = useState({
        title: "Cucufate MX | Acerca de nosotros",
        description:
            "Cucufate es una marca 100% de moda española. Nace por la necesidad de encontrar moda infantil estilo español en Mexico, a precios razonables, con telas españolas, colores neutros y prendas especiales, todas hechas a mano por costureras mexicanas, aportando ese granito de arena contratando mamás de familias mexicanas de Baja California Sur.",
        image: "https://cucufate.s3.us-west-2.amazonaws.com/logo.png",
        url: "https://cucufate.mx/about",
    });

    useEffect(() => {
        dispatch(startGetConfigs());
    }, []);

    const sliders = [];

    const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT;
    for (const clave in configs) {
        if (clave.startsWith("nosotras") && configs[clave]) {
            sliders.push(cloudFrontUrl + configs[clave]);
        }
    }

    return (
        <div className="about">
            <MetaHelmet data={metadata}></MetaHelmet>
            <Header />
            <div>
                {sliders.map((slider, index) => (
                    <img
                        key={index}
                        src={slider}
                        alt={`Slide ${index}`}
                        className="w-100"
                    />
                ))}
            </div>
            <div
                className={
                    styles.welcome +
                    " container animate__animated animate__fadeIn font-size-20"
                }
            >
                <div className="row font-size-20">
                    <div className="col-12 col-md-8 spacing-mb-24 font-size-20">
                        <h1 className="spacing-mb-32">Acerca de Nosotras</h1>
                        <p className={styles.text_justify}>
                            Cucufate es una empresa mexicana fundada en Junio
                            2020 por Ichi, española viviendo en México.
                        </p>
                        <p className={styles.text_justify}>
                            Cucufate es una marca 100% de moda española. Nace
                            por la necesidad de encontrar moda infantil estilo
                            español en Mexico, a precios razonables, con telas
                            españolas, colores neutros y prendas especiales,
                            todas hechas a mano por costureras mexicanas,
                            aportando ese granito de arena contratando mamás de
                            familias mexicanas de Baja California Sur.
                        </p>
                        <p className={styles.text_justify}>
                            Todas las prendas y telas han sido seleccionadas con
                            cuidado, por mamás, cuidando esos detalles
                            importantes en las prendas, para que sean comodas y
                            practicas en los bebes, apostando a que lo bonito
                            puede ser tambien facil de usar y, practico para
                            vestir, dando la opcion con prendas desde Recien
                            Nacido hasta 10 años.
                        </p>
                        <p className={styles.text_justify}>
                            Cucufate ofrece una selección de telas, asi como una
                            cantidad de prendas , con el objetivo de que cada
                            mamá pueda elegir su estilo propio:
                        </p>
                        <p className={styles.text_justify}>
                            La mamá selecciona la prenda y la tela en la talla
                            adecuada, creando su conjunto personalizado. Puede
                            además añadir detalles a las prendas originales.
                            Cuando una mamá ve cucufate, tiene una opinion
                            ilimitada de opciones, pudiendo elegir prendas a su
                            gusto con las telas que mas le gusten, pudiendo
                            combinar hermanos, primos, vistiendo bebes en
                            eventos elegantes o incluso informales.
                        </p>
                        <p className={styles.text_justify}>
                            Cucufate ofrece una tabla de medidas para que las
                            mamás esten comodas y seguras de la talla de los
                            niños. Sin embargo, puede acceder a la atención
                            personalizada, y cobrando un costo extra, se le
                            confecciona prendas con medidas individuales.
                        </p>
                        <Cardinfo color="megaultralightbrown">
                            <h4>
                                ENVÍOS A TODA LA REPÚBLICA, GASTOS DE ENVÍO NO
                                INCLUIDOS.
                            </h4>
                            <h4>
                                TIEMPO APROXIMADO DE PRODUCCIÓN + ENVIO: DE 2 A
                                3 SEMANAS.
                            </h4>
                        </Cardinfo>
                    </div>
                    <div className="col-12 col-md-4">
                        <Cardinfo color="megaultralightbrown">
                            <h4>Datos Importantes:</h4>
                            <ul>
                                <li>
                                    Moda española (estilo español), telas
                                    españolas
                                </li>
                                <li>Hecho a mano</li>
                                <li>Precios razonables</li>
                                <li>Oportunidad de combinar a tus hijos</li>
                                <li>Opción de prenda a medida</li>
                                <li>Eventos especiales</li>
                            </ul>
                        </Cardinfo>
                    </div>
                </div>
            </div>
        </div>
    );
}
