import { types } from "../types";
import { db } from "../../firebase";

export const hadleVerifyCoupon = (coupon) => {
  return async (dispatch) => {
    try {
      let response = await db.collection("coupons").where("code", "==", coupon).where("archived", "==", false).get();

      if (response && response.size > 0) {
        let validCoupon, coupon;

        response.forEach((document) => {
          validCoupon = true;
          coupon = document.data();
        });

        dispatch(verifyCoupon(validCoupon, coupon));
        dispatch(updateCouponMessage("Cupón valido."));
      } else {
        console.log("Cupon no valido", response.size);

        dispatch(updateCouponMessage("Cupón no valido."));
        dispatch(verifyCoupon(false, null));
      }
    } catch (error) {
      console.log("Error al buscar el cupon ingresado", error);
      dispatch(updateCouponMessage("Cupón no valido."));
      dispatch(verifyCoupon(false, null));
    }
  };
};

export const resetCouponState = (validCoupon, coupon) => {
  return (dispatch) => {
    dispatch(verifyCoupon(validCoupon, coupon));
  };
};

const verifyCoupon = (validCoupon, coupon) => {
  return {
    type: types.verifyCoupon,
    payload: {
      validCoupon,
      coupon,
    },
  };
};

export const updateCouponMessage = (message) => {
  return {
    type: types.updateCouponMessage,
    payload: message,
  };
};
