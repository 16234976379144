/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useWizard } from "react-use-wizard";
import styles from "../steps/Steps.module.scss";

import { updateWizardData, updateWizardStepPosition } from "../../../redux/actions/wizard";

import Sleeves from "../aditionals/Sleeves";
import Details from "../aditionals/Details";
import FabricModal from "../aditionals/FabricModal";

import { Button } from "../../utils/ui/Button";
import { useForm } from "../../../hooks/useForm";
import { showModal } from "../../../redux/actions/ui";
import SizesModal from "../aditionals/SizesModal";
import { calculateFabricCost } from "../../../helpers/helpers";

export default function Step3({ executeScroll }) {
  const dispatch = useDispatch();

  const {
    fabricCost,
    step3Options,
    prenda,
    telas,
    tallas,
    tela,
    tela2,
    talla,
    tallaLabel,
    cuello,
    manga,
    tallasTodas,
    fruncido,
    personalizado,
    notas,
    tipo,
  } = useSelector((state) => state.wizard);
  const { handleStep, previousStep, nextStep } = useWizard();

  const [showMsg, setShowMsg] = useState(false);
  const [sizes] = useState(tallas[prenda]);
  const [fabricSelected, setFabricSelected] = useState("");
  const [fabricSelected2, setFabricSelected2] = useState("");
  const [sizeSelected, setSizeSelected] = useState("");
  const [fruncidoSelected, setFruncidoSelected] = useState(fruncido);
  const [personalizadoSelected, setPersonaliadoSelected] = useState(personalizado);

  const [showSizeModal, setShowSizeModal] = useState(false);
  const [minFabricRequired, setMinFabricRequired] = useState(249);

  const [formValues, handleInputChange] = useForm({
    notes: notas,
  });

  const { notes } = formValues;

  useEffect(() => {
    if (prenda !== "CAMISA" && prenda !== "PELELE" && prenda !== "VESTIDOS" && cuello !== "" && manga !== "" && tallaLabel !== "") {
      getFabricCosts();
    }

    if (prenda === "ABRIGO" || prenda === "MOÑO" || prenda === "DONA" || prenda === "PORTACHUPON") getFabricCosts();
  }, []);

  useEffect(() => {
    if (prenda === "CAMISA" || prenda === "PELELE" || prenda === "VESTIDOS") {
      if (cuello !== "" && manga !== "" && tallaLabel !== "") getFabricCosts();
    } else {
      if (tallaLabel !== "") getFabricCosts();
    }
  }, [tallaLabel, cuello, manga]);

  handleStep(() => {
    dispatch(updateWizardStepPosition(4));
  });

  const getFabricCosts = () => {
    let _cost1 = calculateFabricCost(prenda, tipo, cuello, manga, tallaLabel);
    setMinFabricRequired(_cost1);
    setFabricSelected("");
    dispatch(updateWizardData("tela", ""));
    dispatch(updateWizardData("fabricCost", _cost1));

    //console.log("Hacer calculo iniciando especiales", _cost1);
  };

  const handleChangeFabric = (event) => {
    let selected = telas.filter((item) => item.id === event.target.value)[0];

    //console.log("selected", selected);
    setFabricSelected(selected);
    dispatch(updateWizardData("tela", selected.name));
    dispatch(updateWizardData("telaSeleccionada", selected.urlImage));
    dispatch(updateWizardData("idTelaSeleccionada", selected.id));
    dispatch(updateWizardData("actualStockTelaSelected", selected.stock));
  };

  const handleChangeFabric2 = (event) => {
    let selected = telas.filter((item) => item.id === event.target.value)[0];

    //console.log("selected", selected);
    setFabricSelected2(selected);
    dispatch(updateWizardData("tela2", selected.name));
    dispatch(updateWizardData("telaSeleccionada2", selected.urlImage));
    dispatch(updateWizardData("idTelaSeleccionada2", selected.id));
    dispatch(updateWizardData("actualStockTelaSelected2", selected.stock));
  };

  const handleChangeSize = (event) => {
    let selected = event.target.value;

    let selectedItemSizes = tallasTodas.filter((item) => {
      return item.label === selected;
    })[0].value;

    setSizeSelected(selected);
    dispatch(updateWizardData("talla", selectedItemSizes));
    dispatch(updateWizardData("tallaLabel", selected));
  };

  const handleChangueFruncido = (event) => {
    setFruncidoSelected(event.target.value);
    dispatch(updateWizardData("fruncido", event.target.value));
  };

  const handleChanguePersonalizado = (event) => {
    setPersonaliadoSelected(event.target.value);
    dispatch(updateWizardData("personalizado", event.target.value));
  };

  const next = () => {
    if (validate()) {
      setShowMsg(false);
      executeScroll();
      nextStep();
    } else {
      setShowMsg(true);
    }
  };

  const validate = () => {
    let correct = true;

    if (tela === "") {
      correct = false;
    }

    if (sizes !== false) {
      if (talla === "") {
        correct = false;
      }
    }

    if (step3Options[prenda] && tipo !== "VESTIDO_CRUZADO") {
      if (cuello === "") {
        correct = false;
      }

      if (manga === "") {
        correct = false;
      }
    }

    if (tipo === "VESTIDO_CRUZADO") {
      if (tela2 === "") {
        correct = false;
      }
    }

    if (prenda === "PORTACHUPON" && personalizadoSelected === "Personalizado") {
      if (notes === "") {
        correct = false;
      } else {
        dispatch(updateWizardData("notas", notes));
      }
    }

    if (fabricCost === null && fabricCost === undefined && fabricCost === 0) {
      correct = false;
    }

    return correct;
  };

  const returnStep = () => {
    dispatch(updateWizardData("talla", ""));
    dispatch(updateWizardData("tela", ""));
    dispatch(updateWizardData("cuello", ""));
    dispatch(updateWizardData("manga", ""));
    dispatch(updateWizardData("notas", ""));
    dispatch(updateWizardData("fruncido", "Sin_Fruncir"));
    dispatch(updateWizardData("personalizado", "No_Personalizado"));

    executeScroll();
    previousStep();
  };

  return (
    <>
      <h2>Paso 3: Seleccioné una opción</h2>
      <hr />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            {step3Options[prenda] && tipo !== "VESTIDO_CRUZADO" && <Sleeves />}

            <h3 className="mb-2">Selección de talla y tela</h3>
            <div className="row">
              {sizes !== false && (
                <div className="col">
                  <select className="form-select" aria-label="Default select example" value={sizeSelected} onChange={handleChangeSize}>
                    <option value="">Seleccione una talla</option>
                    {sizes.map((item, pos) => (
                      <option key={pos} value={item.label}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <p className="labelModalOptionWizard" onClick={() => setShowSizeModal(!showSizeModal)}>
                    Ver ejemplos de Tallas <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </p>
                  <div className="alert alert-info mt-3">
                    Si requiere una tela específica en algún detalle de la prenda, comuníquese con nosotros directamente o escribirlo en
                    observaciones.
                  </div>
                  {(prenda === "PELELE" || prenda === "VESTIDOS") && (tipo === "VESTIDOS_CORTE_IMPERIO" || tipo === "PELELE_CORTE_IMPERIO") && (
                    <>
                      <h3 className="mb-2">Seleccione una opción por favor</h3>
                      <select className="form-select" aria-label="Default select example" value={fruncidoSelected} onChange={handleChangueFruncido}>
                        <option value="Fruncido">FRUNCIDO</option>
                        <option value="Sin_Fruncir">SIN FRUNCIR</option>
                      </select>
                    </>
                  )}
                </div>
              )}
              {prenda === "PORTACHUPON" && (
                <div className="col">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={personalizadoSelected}
                    onChange={handleChanguePersonalizado}>
                    <option value="Personalizado">PERSONALIZADO</option>
                    <option value="No_Personalizado">NO PERSONALIZADO</option>
                  </select>
                  {personalizado === "Personalizado" && (
                    <>
                      <label className={styles.label + " form-label mt-3"}>Ingrese sus caracteristicas:</label>
                      <textarea
                        className={styles.input + " form-control"}
                        name="notes"
                        rows="4"
                        value={notes}
                        id="notes"
                        onChange={handleInputChange}></textarea>
                    </>
                  )}
                </div>
              )}

              <div className="col">
                <select className="form-select" aria-label="Default select example" value={fabricSelected?.id} onChange={handleChangeFabric}>
                  <option value="">Seleccione una tela</option>
                  {telas.map((item, pos) =>
                    parseInt(item.stock) > minFabricRequired ? (
                      <option key={pos} value={item.id}>
                        {item.name.replace(/_/g, " ")}
                      </option>
                    ) : null
                  )}
                </select>
                <p onClick={() => dispatch(showModal())} className="labelModalOptionWizard">
                  Ver ejemplos de Telas <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </p>

                {fabricSelected !== "" && (
                  <figure className="figure">
                    <img
                      className={`img-thumbnail ${styles.selected + " elevation-005 material"}`}
                      src={fabricSelected.urlImage}
                      alt={fabricSelected.name}
                    />
                    <figcaption className={`figure-caption spacing-mt-16 font-700`}>{fabricSelected.name.replace(/_/g, " ")}</figcaption>
                  </figure>
                )}
              </div>

              {tipo === "VESTIDO_CRUZADO" && (
                <div className="col">
                  <select className="form-select" aria-label="Default select example" value={fabricSelected2?.id} onChange={handleChangeFabric2}>
                    <option value="">Seleccione una tela Olan</option>
                    {telas.map((item, pos) =>
                      parseInt(item.stock) > minFabricRequired ? (
                        <option key={pos} value={item.id}>
                          {item.name.replace(/_/g, " ")}
                        </option>
                      ) : null
                    )}
                  </select>
                  <p onClick={() => dispatch(showModal())} className="labelModalOptionWizard">
                    Ver ejemplos para olan <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </p>

                  {fabricSelected2 !== "" && (
                    <figure className="figure">
                      <img
                        className={`img-thumbnail ${styles.selected + " elevation-005 material"}`}
                        src={fabricSelected2.urlImage}
                        alt={fabricSelected2.name}
                      />
                      <figcaption className={`figure-caption spacing-mt-16 font-700`}>{fabricSelected2.name.replace(/_/g, " ")}</figcaption>
                    </figure>
                  )}
                </div>
              )}
            </div>
            {showMsg && (
              <div className="alert alert-warning mt-3" role="alert">
                Favor de seleccionar los datos requeridos y solicitados en la parte superior para poder continuar.
              </div>
            )}
          </div>
          <div className="col-12 col-md-4">
            <Details />
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-start gap-3 ant-sig">
        <Button color="secondary" theme="outlined" onClick={() => returnStep()}>
          <i className="spacing-mr-8 fas fa-angle-double-left"></i> Anterior
        </Button>
        <Button color="secondary" onClick={() => next()}>
          Siguiente <i className="spacing-ml-8 fas fa-angle-double-right"></i>
        </Button>
      </div>
      <FabricModal />
      <SizesModal showSizeModal={showSizeModal} setShowSizeModal={setShowSizeModal} />
    </>
  );
}
