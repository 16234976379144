import { types } from "../types";

const initialState = {
    fabricCategories: [],
};

export const fabricCategoriesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.getFabricCategories:
            return {
                ...state,
                fabricCategories: payload,
            }
        case types.selectFabricCategory:
            return {
                ...state,
                selectedFabricCategory: payload,
            }
        default:
            return state;
    }
};
