/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import styles from "./Fabric.module.scss";

import { startGetFabrics } from "../../redux/actions/wizard";
import { startGetFabricCategories } from "../../redux/actions/fabricCategories";

export default function Fabrics({ showPath, col1, col2 }) {
  const dispatch = useDispatch();
  const { telas } = useSelector((state) => state.wizard);
  const { fabricCategories } = useSelector((state) => state.fabricCategories);

  const [searchString, setSearchString] = useState("");
  const [selectedCategory, selectCategory] = useState(new URLSearchParams(window.location.search).get("c") || "");

  useEffect(() => {
    dispatch(startGetFabrics());
    dispatch(startGetFabricCategories());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayedTelas = useMemo(() => {
    let telasArray = telas;
    if (selectedCategory !== "") {
      telasArray = telasArray.filter((tela) => tela.category.value === selectedCategory);
    }
    if (searchString !== "") {
      telasArray = telasArray.filter((tela) => tela.name.toLowerCase().includes(searchString.toLowerCase()));
    }
    return telasArray;
  }, [telas, selectedCategory, searchString]);

  return (
    <>
      <div className="container-fluid title-search">
        <div className="container">
          <div className="row  pt-5">
            {showPath && (
              <nav className="col-8" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/">
                      <a>INICIO</a>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active">TELAS</li>
                </ol>
              </nav>
            )}

            <div className="col-12 col-md-6">
              <h1>Nuestras Telas</h1>
            </div>
            <div className="col-12 col-sm-2">
              <select name="categoria" className="form-control" value={selectedCategory} onChange={({ target }) => selectCategory(target.value)}>
                <option value="">Todas</option>
                {fabricCategories.map((item, pos) => (
                  <option key={pos} value={item.id}>
                    {item.name.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-sm-4 text-right">
              <label className="sr-only" htmlFor="inlineFormInputGroup">
                Buscador
              </label>
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i className="fa fa-search"></i>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  ng-model="filter_products"
                  id="inlineFormInputGroup"
                  placeholder="Buscador"
                  name="search"
                  onChange={({ target }) => setSearchString(target.value)}
                  value={searchString}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.welcome + " container animate__animated animate__fadeIn telas-container"}>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              {displayedTelas.map((item, pos) => (
                <div className={`col-4 col-md-3`} key={pos}>
                  <div className="card">
                    <img src={item.urlImage} className="card-img-top" alt="..." />
                    <div className={"card-body "}>
                      <h5 className="card-title">{item.name.replace(/_/g, " ").toLowerCase()}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
