import { types } from "../types";
import { db } from "../../firebase";

export const startGetFabrics = () => {
  return async (dispatch) => {
    let response = await db.collection("fabrics").where("archived", "==", false).where("status.value", "==", "active").orderBy("name", "asc").get();
    let auxArray = [];
    response.forEach((document) => {
      let fabric = document.data();

      if (parseInt(fabric?.stock) > 0) {
        auxArray.push({
          ...document.data(),
          id: document.id,
          key: document.id,
        });
      }
    });
    dispatch(updateWizardData("telas", auxArray));
  };
};

export const resetInitalData = () => {
  return {
    type: types.resetWizardData,
  };
};

export const updateWizardData = (option, data) => {
  return {
    type: types.updateStepData,
    payload: {
      option,
      data,
    },
  };
};

export const updateWizardStepPosition = (step) => {
  return {
    type: types.updateStepPosition,
    payload: step,
  };
};
