/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
//eslint-disable-next-line react-hooks/exhaustive-deps

import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import "font-awesome/css/font-awesome.min.css";

import ProductNotFound from "./ProductNotFound";
import Header from "../utils/Header";
import MetaHelmet from "../metahelmet";

import { startGetSelectedIdeaByID, startGetSelectedIdeaBySlug } from "../../redux/actions/ideas";

export default function Index() {
    const dispatch = useDispatch();
    const { selectedIdea } = useSelector((state) => state.ideas);

    const pathName = window.location.pathname;
    const slug = pathName.includes("/idea/") ? pathName.replace("/idea/", "") : "";
    const productUID = new URLSearchParams(window.location.search).get("uid") || "";

    const [notFound, setNotFound] = useState(false);
    const [checking, setChecking] = useState(true);
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        if (productUID && productUID.length > 0) {
            dispatch(startGetSelectedIdeaByID(productUID));
        } else if (slug && slug.length > 0) {
            dispatch(startGetSelectedIdeaBySlug(slug));
        }
    }, []);

    useEffect(() => {
        if (selectedIdea !== null) {
            setMetadata({
                title: `Cucufate MX | ${selectedIdea.Nombre_del_Producto}`,
                description: selectedIdea.Descripcion,
                image: selectedIdea.Imagen_Principal,
                url: slug,
            });
        } else {
            setNotFound(true);
        }
        setChecking(false);
    }, [selectedIdea]);

    if (checking) {
        //TO DO diseño visual de loader
        return <h1>Espere....</h1>;
    }

    return (
        <Fragment>
            {notFound ? (
                <ProductNotFound />
            ) : (
                <div className="catalogo">
                    <MetaHelmet data={metadata}></MetaHelmet>
                    <Header />
                    <div className="container main light-font">
                        <div className="row mt-4 px-0 mx-0">
                            <nav className="col-12 col-md-8" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <NavLink to="/">
                                            <a>INICIO</a>
                                        </NavLink>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <NavLink to="/ideas">
                                            <a>IDEAS</a>
                                        </NavLink>
                                    </li>
                                    <li className="breadcrumb-item active">{selectedIdea.Nombre}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="container main light-font">
                            <div className="card overflow-hidden mb-4">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="border-bottom spacing-mb-12 spacing-pb-8">
                                                <i class="fa fa-product-hunt"></i> {selectedIdea.Nombre}
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="air__ecommerce__productMainImage mb-3">
                                                <ReactImageMagnify
                                                    {...{
                                                        /*enlargedImagePortalId: 'previewimage',*/
                                                        smallImage: {
                                                            alt: "Wristwatch by Ted Baker London",
                                                            isFluidWidth: true,
                                                            src: selectedIdea.Imagen_Principal,
                                                            srcSet: [].join(", "),
                                                            sizes: "(min-width: 480px) 30vw, 80vw",
                                                        },
                                                        largeImage: {
                                                            alt: "abc",
                                                            src: selectedIdea.Imagen_Principal,
                                                            width: 1313,
                                                            height: 1500,
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div className="d-flex flex-wrap justify-content-left mb-3">
                                                <div className="bookinnjen_productThumb width-100 height-100 border border-primary m-2">
                                                    <img className="img-fluid" src={selectedIdea.Imagen_Principal} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 ">
                                            <div className="tab-content">
                                                <div className="tab-pane active pt-3" id="tab-information-content" role="tabpanel" aria-labelledby="tab-information-content">
                                                    <b>CARACTERÍSTICAS:</b>
                                                    <ul>
                                                        <li>Tela: {selectedIdea.Tela}</li>
                                                        <li>Categoria: {selectedIdea.Categoria}</li>
                                                        <li>Talla: {selectedIdea.Talla}</li>
                                                    </ul>
                                                    <p></p>
                                                </div>
                                                <div className="tab-pane active pt-3" id="tab-information-content2" role="tabpanel" aria-labelledby="tab-information-content">
                                                    <b>DESCRIPCIÓN:</b>
                                                    <p>{selectedIdea.Descripcion}</p>
                                                </div>
                                            </div>
                                            <div id="previewimage" className="previewimagezoom"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}
