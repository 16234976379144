const localstoragekey = "CAT_ITEMSCART";

const addItem = (item) => {
  try {
    let items = localStorage.getItem(localstoragekey) !== null ? JSON.parse(localStorage.getItem(localstoragekey)) : [];

    items.push({
      ...item,
      Cant: 1,
    });
    localStorage.setItem(localstoragekey, JSON.stringify(items));
    return 200;
  } catch (err) {
    console.log("error", err);
    return 500;
  }
};

const getItems = () => {
  if (localStorage.getItem(localstoragekey)) {
    return JSON.parse(localStorage.getItem(localstoragekey));
  } else {
    return [];
  }
};

const updateItems = (item_array) => {
  try {
    localStorage.setItem(localstoragekey, JSON.stringify(item_array));
    return 200;
  } catch (err) {
    console.log("error", err);
    return 500;
  }
};

const getItemsCount = () => {
  let items = 0;

  if (localStorage.getItem(localstoragekey)) {
    let carrito = JSON.parse(localStorage.getItem(localstoragekey));
    items = carrito.length;
  }

  return items;
};

const removeItem = (id, index) => {
  let arrayitems = null;
  localStorage.getItem(localstoragekey) ? (arrayitems = JSON.parse(localStorage.getItem(localstoragekey))) : (arrayitems = []);

  arrayitems.splice(index, 1);

  localStorage.setItem(localstoragekey, JSON.stringify(arrayitems));
};

const emptyCart = () => {
  let arrayitems = [];
  localStorage.setItem(localstoragekey, JSON.stringify(arrayitems));
};

const getItemById = (id) => {
  let items = JSON.parse(localStorage.getItem(localstoragekey));
  let selected_item;
  items.forEach((item) => {
    if (item.ID === id) {
      selected_item = item;
    }
  });
  return selected_item;
};

export { addItem, getItems, getItemsCount, removeItem, emptyCart, updateItems, getItemById };
