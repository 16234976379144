import { types } from "../types"

const initialState = {
    authToken: '',
    countries: [],
    states: [],
    cities: [],
    countriesOptions: [{ value: '', label: 'SELECCIONE UNA OPCIÓN' }],
    statesOptions: [{ value: '', label: 'SELECCIONE UNA OPCIÓN' }],
    citiesOptions: [{ value: '', label: 'SELECCIONE UNA OPCIÓN' }],
}

export const locationReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.setLocationAuthToken:
            return {
                ...state,
                authToken: payload,
            }
        case types.getCountries:
            return {
                ...state,
                countries: payload.countries,
                countriesOptions: payload.countriesOptions,
            }
        case types.getStates:
            return {
                ...state,
                states: payload.states,
                statesOptions: payload.statesOptions,
            }
        case types.getCities:
            return {
                ...state,
                cities: payload.cities,
                citiesOptions: payload.citiesOptions,
            }
        default:
            return state
    }
}