import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Button.scss';

export const Button = ({className, children, color, disabled, fullWidth, fullWidthMobile, fullWidthTablet, icon, onClick, tabIndex, size, theme, type }) => {

    const buttonClasses = {
		[`button-${size}`]: {size},
		[`button-${theme}-${color}`]: {color, theme},
		[`${className}`]: className,
		'button-disabled': disabled, 
		'button-display-block-mobile': fullWidthMobile,
		'button-display-block-tablet': fullWidthTablet,
		'button-display-block': fullWidth,
		'button-icon': icon, 
	};

    return (
        <button
            type={type}
            disabled={disabled}
            className={classNames(buttonClasses)}
            onClick={ onClick }
			tabIndex={ tabIndex}
        >
            { children }
        </button>
    )
}
Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any,
	color: PropTypes.oneOf(['primary','secondary', 'ligth', 'dark', 'red']),
	disabled : PropTypes.bool,
	fullWidth: PropTypes.bool,
	fullWidthMobile: PropTypes.bool,
	fullWidthTablet: PropTypes.bool,
	icon: PropTypes.bool,
	onClick: PropTypes.func,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	tabIndex: PropTypes.number,
	theme:PropTypes.string
}

Button.defaultProps = {
	className: null,
	children: 'Label',
	color: 'primary',
	disabled: false,
	fullWidth: false,
	fullWidthMobile: false,
	fullWidthTablet: false,
	icon:null,
	onClick: null,
	size: 'medium',
	type: 'submit',
	theme: 'contained'
};