/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "font-awesome/css/font-awesome.min.css";

import ItemCard from "../utils/ItemCart";
import Header from "../utils/Header";

import { startGetHomeProducts } from "../../redux/actions/products";

import styles from "./Home.module.scss";
import MetaHelmet from "../metahelmet";
import SocialDetails from "./SocialDetails";
import ButtonWizard from "../utils/ButtonWizard";
import { startGetFabricCategories } from "../../redux/actions/fabricCategories";
//import { showMessage } from "../../redux/actions/ui";

import { startGetConfigs } from "../../redux/actions/configs";
export default function Index() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);

  const { configs } = useSelector((state) => state.configs);

  useEffect(() => {
    dispatch(startGetHomeProducts());
    dispatch(startGetFabricCategories());
    dispatch(startGetConfigs());

    //dispatch(showMessage());
  }, []);

  const [metadata] = useState({
    title: "Bienvenidos a Cucufate MX",
    description: "Cucufate es una empresa mexicana fundada en Junio 2020 por Ichi, española viviendo en México.",
    image: "https://cucufate.s3.us-west-2.amazonaws.com/logo.png",
    url: "https://cucufate.mx/",
  });

  const sliders = [];

  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT;

  for (const clave in configs) {
    if (clave.startsWith("slider") && configs[clave]) {
      sliders.push(cloudFrontUrl + configs[clave]);
    }
  }

  return (
    <div className="home ">
      <MetaHelmet data={metadata}></MetaHelmet>
      <Header />
      <div className={styles.slide + " container-fluid spacing-mb-40 animate__animated animate__fadeIn"}>
        <Carousel emulateTouch={false} interval={7000} autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false}>
          {sliders.map((ruta, index) => (
            <div key={index}>
              <img src={ruta} />
            </div>
          ))}
        </Carousel>
        <div className="ver-mas-info-home">
          <a href="#scroll">
            VER MÁS INFORMACIÓN
            <br />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
          </a>
        </div>
      </div>
      <a name="scroll"></a>
      <ButtonWizard />

      <div className={styles.welcome + " container animate__animated animate__fadeIn"}>
        <div className="row ">
          <div className="col-12 text-center">
            <h1 className="spacing-mb-32">Bienvenidos a Cucufate</h1>
            <p className="font-size-20">En primer lugar, me gustaría presentarme. Soy Ichi, española viviendo en México.</p>
            <p className="font-size-20">
              Me encanta el estilo español de las prendas de los más pequeños de la casa, y cuando nació mi bebé en México, <br />
              me costaba encontrar prendas de este estilo, sencillas, colores pasteles y a buen precio. Por eso, nació Cucufate.
            </p>
            <p className="font-size-20">Cucufate trabaja por encargo, con pedidos totalmente personalizados, a precios muy accesibles:</p>
            <p className="font-size-20">
              <strong>Selecciona la prenda que más te guste, la talla, la tela y listo!</strong>
            </p>
          </div>
        </div>
      </div>

      <div className="container animate__animated animate__fadeIn">
        <SocialDetails />
      </div>

      <div className="container spacing-mb-80 mt-5">
        <div className={styles.home_products_cards}>
          <div className="row mx-0 px-0 gap-3 gap-sm-0">
            <h2 className="text-center spacing-mb-32 animate__animated animate__fadeIn">Nuestros Productos</h2>
            <p className="text-center spacing-mb-80 animate__animated animate__fadeIn font-size-20">
              Las telas que utilizamos son 100% algodón para cuidar la piel tan delicada de los bebes.
            </p>
            {products.map((product, index) => {
              return (
                <Fragment key={index}>
                  <ItemCard product={product} index={index} page="home-product" />
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
