/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { validateName } from "../../../helpers/cleanParams";

const Details = ({ showTitle = true }) => {
  const { prenda, tipo, cuello, manga, tela, tela2, tallaLabel, talla, fruncido, precio, details, personalizado } = useSelector(
    (state) => state.wizard
  );
  const [show, setShow] = React.useState();

  return (
    <>
      {showTitle && (
        <>
          <h3 className="spacing-mb-16">Detalles</h3>
          <p className="body-regular">Acontinuación se visualizarán los detalles seleccionados en todo el wizard de su prenda.</p>
        </>
      )}

      <hr />

      <ul className="details-text">
        <li className="">
          <span>Prenda:</span> {prenda.replace(/_/g, " ").toLowerCase()}
        </li>

        {tipo !== "" && (
          <li className="">
            <span>Tipo:</span> {validateName(tipo.replace(/_/g, " ")).toLowerCase()}
          </li>
        )}

        {cuello !== "" && (
          <li className="">
            <span>Cuello:</span> {cuello.replace(/_/g, " ").toLowerCase()}
          </li>
        )}

        {manga !== "" && (
          <li className="">
            <span>Manga:</span> {manga.toLowerCase()}
          </li>
        )}

        {tela !== "" && (
          <li className="">
            <span>Tela:</span> {tela.replace(/_/g, " ").toLowerCase()}
          </li>
        )}

        {tipo === "VESTIDO_CRUZADO" && (
          <li className="">
            <span>Tela Olan:</span> {tela2?.replace(/_/g, " ").toLowerCase()}
          </li>
        )}

        {talla !== "" && (
          <li className="">
            <span>Talla:</span> {tallaLabel.toLowerCase()}
          </li>
        )}

        {(prenda === "PELELE" || prenda === "VESTIDOS") && (tipo === "VESTIDOS_CORTE_IMPERIO" || tipo === "PELELE_CORTE_IMPERIO") && (
          <li className="">
            <span>Opción:</span> {fruncido.replace(/_/g, " ").toLowerCase()}
          </li>
        )}

        {prenda === "PORTACHUPON" && (
          <li className="">
            <span>Opción:</span> {personalizado.replace(/_/g, " ").toLowerCase()}
          </li>
        )}
      </ul>

      {precio === 0 && showTitle && (
        <>
          <div className={`descripcion-prenda ${show ? "expand" : ""}`}>{details[prenda]} </div>

          <div className="more">
            <a className={`${show ? "hide" : ""}`} onClick={() => setShow(!show)}>
              Ver más &#x2771;
            </a>
            <a className={`${show ? "" : "hide"}`} onClick={() => setShow(!show)}>
              {" "}
              &#x2770; Ver menos
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default Details;
