/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { store } from "./redux/store";
import AppRouter from "./routers/appRouter";

const STRIPE_DEV = loadStripe("pk_test_51L0TrbE8hxjSUHHgkRMeQkxmVIPSGpB8muLqI5X8x3vxAa7xMs21u0LdnPS9bmB2PRJqlIx2Gy66knJcHJgBH3Ts00KAahrC0d");
const STRIPE_PROD = loadStripe("pk_live_51L0TrbE8hxjSUHHgWj6BjBXg5dNUXNN34tLlq2g55HDRLPGiCjIBBfTEon2cMmt4NtCMB3H9r0iwjjcxMLLQpYTp00SawmpAYg");

function App() {
  const [selectedTheme, setTheme] = useState("cucufate");

  useEffect(() => {
    changeTheme(selectedTheme);
  }, []);

  const changeTheme = (theme) => {
    setTheme(theme);
    document.documentElement.className = "";
    document.documentElement.classList.add(`theme-${selectedTheme}`);
  };

  return (
    <Elements stripe={STRIPE_PROD}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </Elements>
  );
}

export default App;
