/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FacebookProvider, Page } from "react-facebook";

import validator from "validator";
import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "./Contact.module.scss";
import { Button } from "../utils/ui/Button";
import Header from "../utils/Header";
import MetaHelmet from "../metahelmet";
import { useForm } from "../../hooks/useForm";
import { sendContactEmail } from "../../redux/actions/contact";
import { startGetConfigs } from "../../redux/actions/configs";

export default function Index() {
  const dispatch = useDispatch();
  const { messageEmailSended } = useSelector((state) => state.contact);
  const { configs } = useSelector((state) => state.configs);

  const [metadata] = useState({
    title: "Cucufate MX | Contactenos",
    description:
      "CucufateMX, puede contactarnos de las siguiente maneras: Teléfono: 613 125 15 52 | Ubicación: Loreto, Baja California Sur | Correo: ventas@cucufate.mx",
    image: "https://cucufate.s3.us-west-2.amazonaws.com/logo.png",
    url: "https://cucufate.mx/contact",
  });

  useEffect(() => {
    dispatch(startGetConfigs());
  }, []);

  const sliders = [];

  const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT;

  for (const clave in configs) {
    if (clave.startsWith("contactenos") && configs[clave]) {
      sliders.push(cloudFrontUrl + configs[clave]);
    }
  }
  const [showError, setShowError] = useState(false);

  const [formValues, handleInputChange, handleSelectChange, reset, setValues] = useForm({
    fullname: "",
    email: "",
    phone: "",
    title: "",
    message: "",
    type: "CONTACT",
  });

  const { fullname, email, phone, title, message } = formValues;

  const handleForm = () => {
    if (validateForm()) {
      dispatch(sendContactEmail(formValues));
      setShowError(false);
      reset();
    } else {
      console.log("Faltan parametros");
      setShowError(true);
    }
  };

  const validateForm = () => {
    let correct = true;

    if (fullname === "") {
      correct = false;
    }
    if (email === "") {
      correct = false;
    }
    if (phone === "") {
      correct = false;
    }
    if (title === "") {
      correct = false;
    }
    if (message === "") {
      correct = false;
    }

    if (!validator.isEmail(email)) {
      correct = false;
    }

    if (!validator.isMobilePhone(phone, "es-MX")) {
      correct = false;
    }

    return correct;
  };

  return (
    <div className="home">
      <MetaHelmet data={metadata}></MetaHelmet>
      <Header />
      <div className={"slide container-fluid animate__animated animate__fadeIn"}>
        {sliders.map((ruta, index) => (
          <div key={index}>
            <img src={ruta} />
          </div>
        ))}
      </div>
      <div className={styles.welcome + " container animate__animated animate__fadeIn"}>
        <div className="row spacing-mb-32">
          <div className="col-12">
            <h1 className="spacing-mb-32">Contáctenos</h1>
            <h4>Ubicación: Loreto, Baja California Sur</h4>
            <h4>Teléfono: 613 125 15 52</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h3>Formulario de Contacto:</h3>
          </div>
          <div className="col-12 col-md-8 ">
            <div className="row">
              <div className="col-6 col-lg-6 col-xl-6 my-3">
                <label className={styles.label + " form-label"}>Nombre completo</label>
                <input
                  type="text"
                  name="fullname"
                  className={styles.input + " form-control"}
                  value={fullname}
                  id="fullname"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-6 col-lg-6 col-xl-6 my-3">
                <label className={styles.label + " form-label"}>Correo electronico</label>
                <input type="text" name="email" className={styles.input + " form-control"} value={email} id="email" onChange={handleInputChange} />
              </div>
              <div className="col-6 col-lg-6 col-xl-6 my-3">
                <label className={styles.label + " form-label"}>Asunto</label>
                <input type="text" name="title" className={styles.input + " form-control"} value={title} id="title" onChange={handleInputChange} />
              </div>
              <div className="col-6 col-lg-6 col-xl-6 my-3">
                <label className={styles.label + " form-label"}>Telefono</label>
                <input
                  type="text"
                  name="phone"
                  maxLength="10"
                  className={styles.input + " form-control"}
                  value={phone}
                  id="phone"
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-12 col-lg-12 col-xl-12 my-3">
                <label className={styles.label + " form-label"}>Mensaje</label>
                <textarea
                  className={styles.input + " form-control"}
                  name="message"
                  rows="3"
                  value={message}
                  id="message"
                  onChange={handleInputChange}></textarea>

                {showError && (
                  <div class="alert alert-warning mt-3" role="alert">
                    Favor de ingresar todos los datos solicitados en el formulario correctamente.
                  </div>
                )}
              </div>

              <div className="col-6 col-lg-6 col-xl-6 my-3">
                <Button type="button" color="secondary" fullWidth onClick={handleForm}>
                  <i className="fa fa-envelope"></i> &nbsp; Enviar
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <FacebookProvider appId="214398075255539">
              <Page href="https://www.facebook.com/cucufate_cucufate-108129217716383" tabs="timeline" />
            </FacebookProvider>
          </div>
          {/*<div className="col-12 col-md-12 mt-5">
                        <h3 className="spacing-mb-32">Ubicación</h3>
                        <Map />
                    </div>*/}
        </div>
      </div>
    </div>
  );
}
