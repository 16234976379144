import { db } from "../../firebase";
import { types } from "../types";

const col = "ideas";

export const startGetIdeas = () => {
    return async (dispatch) => {
        let response = await db.collection(col).where("archived", "==", false).get();
        let productsParsed = [];
        response.forEach((document) => {
            let product = document.data();
            product.ID = document.id;
            product.KEY = document.id;
            productsParsed.push(product);
        });
        dispatch(getIdeas(productsParsed));
    };
};

export const startGetSelectedIdeaBySlug = (slug) => {
    return async (dispatch) => {
        let response = await db.collection(col).where("Slug", "==", slug).get();
        if (response && response.size > 0) {
            let product;
            response.forEach((document) => {
                product = document.data();
                product.ID = document.id;
            });
            dispatch(selectIdea(product));
        } else {
            dispatch(selectIdea(null));
        }
    };
};

export const startGetSelectedIdeaByID = (id) => {
    return async (dispatch) => {
        let response = await db.collection(col).doc(id).get();
        if (response.exists) {
            let product = response.data();
            product.ID = response.id;
            dispatch(selectIdea(product));
        } else {
            dispatch(selectIdea(null));
        }
    };
};

export const selectIdea = (product) => {
    return {
        type: types.selectIdea,
        payload: product,
    };
};

export const unselectIdea = () => {
    return {
        type: types.unselectIdea,
    };
};

const getIdeas = (products) => {
    return {
        type: types.getIdeas,
        payload: products,
    };
};
