import { sendEmailNew } from "../../controllers/api_controller";
import { types } from "../types";

import Swal from "sweetalert2";

export const sendContactEmail = (data) => {
    return async (dispatch) => {
        try {
            let response = await sendEmailNew(data);
            if (response.data?.msg === "ok") {
                Swal.fire("", "Mensaje enviado correctamente, pronto nos pondremos en contacto con usted.", "success");
                dispatch(emailSended(response.data.msg));
            } else {
                Swal.fire("", "El mensaje no se pudo enviar, favor de contactarnos por otro medio.", "warning");
                dispatch(emailSended(""));
            }
        } catch (error) {}
    };
};

export const emailSended = (message) => {
    return {
        type: types.contactSendEmail,
        payload: message,
    };
};
