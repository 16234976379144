/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import { Cardinfo } from "../utils/ui/Cardinfo";

export default function SocialDetails() {
  return (
    <Cardinfo className="row p-3 pt-5 pb-5 justify-content-between card-info-instagram">
      <div className="col-12 spacing-mb-16">
        <h2 className="text-center">Visita nuestro instagram:</h2>
      </div>
      <div className="info_bar_item col-3 mb-lg-3 mb-3">
        <a
          href="https://www.instagram.com/stories/highlights/17849045039651548/"
          target="_blank"
          className="body-small d-flex flex-column font-size-16">
          <img src="assets/info-images/icono-bebes.png" />
          <h6>BEBES</h6>
        </a>
      </div>
      <div className="info_bar_item col-3 mb-lg-3 mb-3">
        <a
          href="https://www.instagram.com/stories/highlights/18207613573181900/"
          target="_blank"
          className="body-small d-flex flex-column font-size-16">
          <img src="assets/info-images/icono-pajes.png" />
          <h6>PAJES</h6>
        </a>
      </div>
      <div className="info_bar_item col-3 mb-lg-3 mb-3">
        <a
          href="https://www.instagram.com/stories/highlights/17891143721103713/"
          target="_blank"
          className="body-small d-flex flex-column font-size-16">
          <img src="assets/info-images/icono-testimonios.png" />
          <h6>TESTIMONIOS</h6>
        </a>
      </div>
      <div className="info_bar_item col-3 mb-lg-3 mb-3">
        <a
          href="https://www.instagram.com/stories/highlights/18046192006306327/"
          target="_blank"
          className="body-small d-flex flex-column font-size-16">
          <img src="assets/info-images/icono-preguntas.png" />
          <h6>PREGUNTAS</h6>
        </a>
      </div>
    </Cardinfo>
  );
}
