import React from "react";
import { useDispatch } from "react-redux";
import "font-awesome/css/font-awesome.min.css";
import ProductModal from "./ProductModal";
import { Button } from "./ui/Button";

import classNames from "classnames";
import styles from "./ItemCard.module.scss";

import { trimString } from "../../utilities/string_parser";
import { DiscountOffer } from "./ui/DiscountOffer";
import { selectProduct } from "../../redux/actions/products";
import { showModal } from "../../redux/actions/ui";

export default function ItemCard({ index, page, product }) {
  const dispatch = useDispatch();

  const itemClasess = {
    product: true,
    [`${page}`]: page,
    "col-12 col-sm-6 col-lg-3": page === "home-product",
    "col-12 col-sm-6 col-lg-4": page === "catalog-product",
  };

  const handleAddToCart = () => {
    //dispatch(addItemToCart(product));
    dispatch(selectProduct(product));
    dispatch(showModal());
  };

  const handleGoToDetails = () => {
    if (product?.Slug) {
      window.location.href = `/producto/${product?.Slug}`;
    } else if (product.id) {
      window.location.href = `/producto?uid=${product?.id}`;
    }
  };

  return (
    <div className={`${classNames(itemClasess)} animate__animated animate__fadeIn`}>
      <div className={styles.item + " overflow-hidden item"}>
        {product?.Inventario === "0" && <div className={styles.item_nuevo}>AGOTADO</div>}
        {product?.Precio_Oferta !== "-" && (
          <div className={styles.item_descount}>
            <DiscountOffer colorText="white" descuento={product?.Descuento_Oferta} />
          </div>
        )}
        <div className={styles.item_image_container}>
          <a href={product?.slug ? `producto/${product?.slug}` : "#"} onClick={handleGoToDetails}>
            <img src={product?.Imagen_Principal} alt="" />
          </a>
        </div>
        <div className={styles.item_body_card}>
          <a
            data-toggle="tooltip"
            title={product.Nombre_del_Producto}
            href={product.slug ? `producto/${product.slug}` : "#"}
            onClick={handleGoToDetails}>
            <h3 className={styles.item_title}>{trimString(product.Nombre_del_Producto, 25)}</h3>
          </a>

          <div className={styles.item_stars + " text-gold"} style={{ color: "gold" }}>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
          </div>
        </div>
        <div className={styles.item_body_card}>
          <div className={styles.item_price + " spacing-mb-12 spacing-pr-8 d-flex align-items-center justify-content-end"}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <span className={styles.spans_smalls}>Marca: CUCUFATE</span> <br />
                  <div className="row">
                    <div class="col-12">
                      {product?.Tallas?.length > 0 && (
                        <li style={{ display: "inline" }} className={styles.spans_smalls}>
                          Tallas Disponibles: <br />
                          {product?.Tallas?.slice(0, 4).join(" | ")}...
                        </li>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-1">
                  {product.Precio_Oferta !== "-" ? (
                    <div className="d-flex justify-content-end flex-column">
                      <span className={"body-regular text-right"}>
                        <del>$ {product.Precio}</del>
                      </span>
                      <h4 className={styles.item_price_off}>${product.Precio_Oferta}</h4>
                    </div>
                  ) : (
                    <h4 className={styles.item_price_regular}>${product.Precio}</h4>
                  )}
                </div>
                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-6 p-1">
                      <Button size={"small"} type="button" color={"primary"} onClick={handleGoToDetails} fullWidth>
                        Ver detalles <i class="fa fa-chevron-right" aria-hidden="true"></i>
                      </Button>
                    </div>
                    <div className="col-6 p-1">
                      <Button
                        disabled={product.Inventario === "0"}
                        type="button"
                        size={"small"}
                        color={product.Inventario === "0" ? "gray" : "secondary"}
                        onClick={handleAddToCart}
                        fullWidth>
                        <i className="fa fa-shopping-cart"></i> &nbsp; Agregar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-1">
            <span className={`${styles.badge} badge text-caption spacing-my-8`}>
              <i className="fas fa-tags spacing-mr-8"></i>
              {product.Categoria}
            </span>
          </div>
          <div className={styles.item_short_description_container}>
            <p className={styles.item_short_description_text}>{trimString(product.Descripcion_Corta, 70)}</p>
          </div>
        </div>
      </div>
      <ProductModal />
    </div>
  );
}
