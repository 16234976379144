import fabric_costs from "../helpers/fabric_costs.json";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const applyDiscount = (price, discount) => {
  let _discount = (parseFloat(price) * parseFloat(discount)) / 100;
  return _discount;
};

export const deleteProductSwalConfigs = {
  title: "¿Esta seguro de eliminar el producto seleccionado?",
  text: "Una vez eliminado, tendrá la posibilidad de volverlo a agregar.",
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Aceptar",
};

export const orderRegisteredSwalConfigs = {
  text: "Su órden se ha registrado con éxito.",
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Aceptar",
};

export const parseEmail = (order, id) => {
  return {
    email: order.customer_data.contactEmail,
    data: {
      id,
      customer: {
        fullname: order.customer_data.contactName,
        email: order.customer_data.contactEmail,
        address: order.customer_data.contactAddress,
      },
      payment_method: order.payment_data.paymentMethod,
      shipping_method: order.payment_data.sender,
      totals: {
        sub_total: order.totals.subtotal,
        send_amount: order.totals.shipping,
        total_master: order.totals.total,
      },
      products: order.items.map((item) => {
        return {
          name: item.Nombre_del_Producto,
          count: item.Cant,
          price: item.Precio,
          total: parseInt(item.Cant) * parseFloat(item.Precio),
        };
      }),
    },
  };
};

export const getCardStripeStyle = () => {
  return {
    style: {
      base: {
        color: "#4F6F7C",
        backgroundColor: "#ffffff",
        padding: ".0.5rem 0.3rem",
        fontFamily: "sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
};

export const showTotalAux = (cartProducts, shipping) => {
  let subtotal = 0;
  cartProducts.forEach((product) => {
    if (product.Precio_Oferta === "-") {
      subtotal += parseInt(product.Precio) * parseInt(product.Cant);
    } else {
      subtotal += parseInt(product.Precio_Oferta) * parseInt(product.Cant);
    }
  });

  let total = subtotal + shipping;
  return total;
};

export const calculateTotal = (cartProducts, shipping, validCoupon, couponAdded) => {
  let subtotal = 0;
  cartProducts.forEach((product) => {
    if (product.Precio_Oferta === "-") {
      subtotal += parseInt(product.Precio) * parseInt(product.Cant);
    } else {
      subtotal += parseInt(product.Precio_Oferta) * parseInt(product.Cant);
    }
  });

  let total = subtotal + shipping;
  if (validCoupon) {
    const { percent } = couponAdded;
    let discount = calculatePercentCouponDiscount(parseInt(percent), total);

    total -= discount;
  }
  return total;
};

export const calculateSubtotal = (cartProducts) => {
  let subtotal = 0;
  cartProducts.forEach((product) => {
    if (product.Precio_Oferta === "-") {
      subtotal += parseInt(product.Precio) * parseInt(product.Cant);
    } else {
      subtotal += parseInt(product.Precio_Oferta) * parseInt(product.Cant);
    }
  });
  return subtotal;
};

export const calculatePercentCouponDiscount = (discount, total) => {
  return (parseInt(total) * parseInt(discount)) / 100;
};

export const emptyCartSwalConfig = () => {
  return {
    title: "Mensaje",
    text: "De momento no tiene productos agregados al carrito....",
    confirmButtonText: '<i className="fa fa-thumbs-up"></i> Aceptar',
  };
};

export const parseSleeveName = (name) => {
  let arrayName = name.split(".png");
  return arrayName[0].replace("./", "").toUpperCase();
};

export const parseNameToImg = (name, min = false) => {
  if (min) {
    console.log(`${name}.png`.toLowerCase());
    return `${name}.png`.toLowerCase();
  }

  let nameAux = name.replace(/ /g, "_");
  return `${nameAux}.png`;
};

export const createCustomProduct = (product) => {
  const {
    prenda,
    tipo,
    cuello,
    manga,
    tela,
    tela2,
    tallaLabel,
    precio,
    fruncido,
    personalizado,
    notas,
    otros,
    obser,
    telaSeleccionada,
    fabricCost,
    idTelaSeleccionada,
    actualStockTelaSelected,
    telaSeleccionada2,
  } = product;

  let idMagic = Number(new Date());
  let detailsPorta = "";

  if (prenda === "PORTACHUPON") {
    detailsPorta = `${personalizado.replace(/_/g, " ")}, `;

    if (personalizado === "Personalizado") {
      detailsPorta += ` Detalles: (${notas}),`;
    }
  }

  let myProduct = {
    Caracteristicas_Extra: "-",
    Caracteristicas_Principales: "-",
    Categoria: prenda,
    Contenido: "-",
    Descripcion_Corta: `${prenda} | ${tipo.replace(/_/g, " ")}`,
    Descripcion_Larga: `${prenda} | ${tipo.replace(/_/g, " ")} (${cuello !== "" ? cuello + "," : ""} ${manga !== "" ? manga + "," : ""} ${
      prenda === "PELELE" || prenda === "VESTIDOS" ? fruncido.replace(/_/g, " ").toUpperCase() + "," : ""
    } ${detailsPorta}  Tela: ${tela.replace(/_/g, " ")}, Talla: ${tallaLabel})`,
    Descuento_Oferta: "-",
    EAN: "producto-personalizado",
    Fin_Oferta: "-",
    ID: idMagic,
    Imagen_Principal: "https://cucufate.s3.us-west-2.amazonaws.com/logo.png",
    Inicio_Oferta: "-",
    Inventario: "1",
    KEY: idMagic,
    Linea: "bebes",
    Marca: "cucufate",
    Nombre_del_Producto: `${prenda} | ${tipo.replace(/_/g, " ")} ${prenda === "PORTACHUPON" ? personalizado.replace(/_/g, " ").toUpperCase() : ""}`,
    Peso: "-",
    Precio: precio,
    Precio_Oferta: "-",
    SKU: `producto-personalizado-${prenda.toLowerCase()}`,
    Sub_categoria: prenda,
    Sub_marca: "-",
    archived: false,
    slug: `producto-personalizado-${prenda.toLowerCase()}`,
    Observaciones: obser,
    Personalizado: {
      prenda,
      tipo,
      cuello,
      manga,
      tela,
      tela2,
      tallaLabel,
      precio,
      fruncido,
      personalizado,
      notas,
      observaciones: obser,
      otros,
      telaSeleccionada,
      telaSeleccionada2,
    },
    Costo_Tela: fabricCost,
    Id_Tela: idTelaSeleccionada,
    Actual_Stock: actualStockTelaSelected,
  };

  if (otros) {
    myProduct.Images = {
      part1: `${tipo}_${cuello}`,
      part2: parseNameToImg(manga, true),
    };
  } else {
    myProduct.Images = {
      part1: `OTROS`,
      part2: parseNameToImg(tipo),
    };
  }

  return myProduct;
};

export const calculatePrice = (prenda, tipo, manga, talla, prices, personalizado) => {
  switch (prenda) {
    case "CAMISA":
      if (manga === "SIN MANGA" || manga === "SIN MANGA OLAN") {
        let mangaAux = manga.replace(/ /g, "_");
        return prices[prenda][mangaAux][talla];
      }

      return prices[prenda][talla];

    case "CUBREPAÑAL":
      return prices[prenda][tipo][talla];

    case "CAPOTA":
      return prices[prenda][tipo][talla];

    case "RANITA":
      return prices[prenda][talla];

    case "PANTALON":
      return prices[prenda][tipo][talla];

    case "PETO":
      return prices[prenda][tipo][talla];

    case "PETO_BRAGUITA":
      return prices["PETO"][tipo][talla];

    case "SHORT":
      return prices[prenda][tipo][talla];

    case "FALDA":
      return prices[prenda][tipo][talla];

    case "PELELE":
      if (manga === "SIN MANGA" || manga === "SIN MANGA OLAN") {
        let mangaAux = manga.replace(/ /g, "_");
        return prices[prenda][tipo][mangaAux][talla];
      }

      return prices[prenda][tipo][talla];

    case "VESTIDOS":
      if (manga === "SIN MANGA" || manga === "SIN MANGA OLAN") {
        let mangaAux = manga.replace(/ /g, "_");
        return prices[prenda][tipo][mangaAux][talla];
      }

      return prices[prenda][tipo][talla];

    case "ABRIGO":
      return prices[prenda][talla];

    case "MOÑO":
      return prices[prenda][tipo];

    case "FAJIN":
      return prices[prenda][tipo][talla];

    case "PORTACHUPON":
      return prices[prenda][personalizado.toUpperCase()];
    case "DONA":
      return prices[prenda];

    default:
      return null;
  }
};

export const calculateFabricCost = (prenda, tipo, cuello, manga, talla) => {
  //console.log("->", prenda, tipo, cuello, manga, talla);

  switch (prenda) {
    case "CAMISA":
      if (manga === "SIN MANGA" || manga === "SIN MANGA OLAN") {
        return fabric_costs[tipo][cuello]["SIN_MANGA"][talla];
      }

      return fabric_costs[tipo][cuello][talla];

    case "CUBREPAÑAL":
      return fabric_costs[tipo][talla];

    case "CAPOTA":
      return fabric_costs[prenda][talla];

    case "RANITA":
      return fabric_costs[prenda][talla];

    case "PANTALON":
      return fabric_costs[prenda][tipo][talla];

    case "PETO":
      return fabric_costs[tipo][talla];

    case "SHORT":
      return fabric_costs[tipo][talla];

    case "FALDA":
      return fabric_costs[tipo][talla];

    case "PELELE":
      if (manga === "SIN MANGA" || manga === "SIN MANGA OLAN") {
        return fabric_costs[tipo][cuello]["SIN_MANGA"][talla];
      }

      return fabric_costs[tipo][cuello][talla];

    case "VESTIDOS":
      if (manga === "SIN MANGA" || manga === "SIN MANGA OLAN") {
        return fabric_costs[tipo][cuello]["SIN_MANGA"][talla];
      }

      return fabric_costs[tipo][cuello][talla];

    case "ABRIGO":
      return fabric_costs[tipo][talla];

    case "MOÑO":
      return fabric_costs[prenda];

    case "FAJIN":
      return fabric_costs[prenda][talla];

    case "PORTACHUPON":
      return fabric_costs[prenda];

    case "DONA":
      return fabric_costs[prenda];

    default:
      return null;
  }
};
