import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { hideModal } from "../../../redux/actions/ui";
import Fabrics from "../../fabrics/Fabrics";

export default function FabricModal() {
  const dispatch = useDispatch();

  const { showModal } = useSelector((state) => state.ui);

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  return (
    <Modal isOpen={showModal} toggle={handleCloseModal} size="lg" className="modalFabricsWizard">
      <ModalBody>
        <div className="container-fluid">
          <div className="form-row">
            <div className="col-12 col-lg-12 col-xl-12 my-3 fabricModalContent">
              <Fabrics showPath={false} col1={2} col2={2} />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger mr-auto" onClick={handleCloseModal}>
          ACEPTAR
        </button>
      </ModalFooter>
    </Modal>
  );
}
