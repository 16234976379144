/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Fade } from "react-animation-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import styles from "./CategorySelect.module.scss";

export default function CategorySelect({ handleCategorySelect, category }) {
    return (
        <Fade in>
            <a
                className={`list-group-item list-group-item-action ${styles.list_group}`}
                onClick={() => handleCategorySelect(category)}>
                <FontAwesomeIcon icon={faTags} className="mr-1" />
                {category}
            </a>
        </Fade>
    );
}
