import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "../steps/Steps.module.scss";
import { updateWizardData } from "../../../redux/actions/wizard";
import { parseSleeveName } from "../../../helpers/helpers";
import { getImages } from "../../../helpers/images";

export default function Sleeves() {
  const dispatch = useDispatch();
  const { step3aOptions, tipo, cuello, manga } = useSelector((state) => state.wizard);

  const [collars] = useState(step3aOptions[tipo]);

  const [collarSelected, setCollarSelected] = useState("");
  const [selectedOption, setSelectedOption] = useState(manga);
  const [selectedOptionSleeve, setSelectedOptionSleeve] = useState(cuello);

  const [sleeves] = useState(getImages());

  const handleSelectedOption = (item) => {
    setSelectedOption(item);
    dispatch(updateWizardData("manga", item));
  };

  const handleSelectedOptionSleeve = (item) => {
    setSelectedOptionSleeve(item);

    setCollarSelected(item);
    dispatch(updateWizardData("cuello", item));
  };

  return (
    <div>
      <h3>Seleccioné el tipo de cuello</h3>
      <p>Una vez seleccionado el tipo de cuello podrá visualizar las mangas, existentes.</p>

      <div className="row">
        {collars.map((item, pos) => (
          <div className="col-6 col-sm-3 col-lg-3" key={pos}>
            <figure className="figure item-container" onClick={() => handleSelectedOptionSleeve(item)}>
              {parseSleeveName(item) === selectedOptionSleeve && <i className="fas fa-check-circle"></i>}
              <img
                className={`img-thumbnail ${item === selectedOptionSleeve ? styles.selected + " elevation-01" : null}`}
                src={sleeves[`SLEEVES`](`./${item}.png`)}
                alt={item}
              />
              <figcaption className={`figure-caption spacing-mt-16 ${parseSleeveName(item) === selectedOptionSleeve ? "font-700" : null}`}>
                {item.replace(/_/g, " ")}
              </figcaption>
            </figure>
          </div>
        ))}
      </div>

      <hr />
      <div className="row">
        <h3>Mangas</h3>
        {cuello !== "" &&
          sleeves &&
          collarSelected !== "" &&
          sleeves[`${tipo}_${cuello}`].keys().map((item, pos) => (
            <div className="col-6 col-sm-3 col-lg-2" key={pos}>
              <figure className="figure item-container" onClick={() => handleSelectedOption(parseSleeveName(item))}>
                {parseSleeveName(item) === selectedOption && <i className="fas fa-check-circle"></i>}
                <img
                  className={`img-thumbnail ${parseSleeveName(item) === selectedOption ? styles.selected + " elevation-01" : null}`}
                  src={sleeves[`${tipo}_${cuello}`](item)}
                  alt={item}
                />
                <figcaption className={`figure-caption spacing-mt-16 ${parseSleeveName(item) === selectedOption ? "font-700" : null}`}>
                  {parseSleeveName(item)}
                </figcaption>
              </figure>
            </div>
          ))}
      </div>
      <hr />
    </div>
  );
}
