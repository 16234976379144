/* eslint-disable no-dupe-keys */
import React from "react";
import "font-awesome/css/font-awesome.min.css";

import classNames from "classnames";
import styles from "./ItemCard.module.scss";

import { trimString } from "../../utilities/string_parser";

export default function ItemIdea({ index, page, product }) {
  const itemClasess = {
    product: true,
    [`${page}`]: page,
    "col-12 col-sm-6 col-lg-3": page === "home-product",
    "col-12 col-sm-6 col-lg-3": page === "catalog-product",
  };

  const handleGoToDetails = () => {
    if (product.Slug) {
      window.location.href = `/idea/${product.Slug}`;
    } else if (product.id) {
      window.location.href = `/idea?uid=${product.id}`;
    }
  };

  return (
    <div className={`${classNames(itemClasess)} animate__animated animate__fadeIn`}>
      <div className={styles.item + " overflow-hidden item"}>
        <div className={styles.item_image_container}>
          <a href={product.slug ? `idea/${product.Slug}` : "#"} onClick={handleGoToDetails}>
            <img src={product.Imagen_Principal} alt="" />
          </a>
        </div>
        <div className={styles.item_body_card}>
          <div className={styles.item_product_name_stars}>
            <a href={product.slug ? `idea/${product.Slug}` : "#"} onClick={handleGoToDetails}>
              <div className="card_item_title">
                <h3 className={styles.item_title}>{product.Nombre}</h3>
              </div>
            </a>

            <div className={styles.item_stars + " text-gold"} style={{ color: "gold" }}>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>

            <div className="">
              <span className={`${styles.badge} badge text-caption spacing-my-8`}>
                <i className="fas fa-tags spacing-mr-8"></i> Categoría: {product.Categoria}
              </span>
            </div>
          </div>
          <div className={styles.item_short_description}>
            <p>{trimString(product.Descripcion, 70)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
