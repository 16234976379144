import { types } from "../types"

const initialState = {}

export const authReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch(type) {
        case types.login: 
            return {
                uid: payload.uid,
                email: payload.email
            }
        case types.logout:
            return {}
        default:
            return state
    }
}