export const validateName = (name) => {
  let _name = name;

  //console.log(_name);

  if (name.includes("PETO")) {
    switch (_name.replace(/\s/g, "")) {
      case "PETOCONBRAGUITA":
        _name = "PETO BRAGUITA";
        break;
      case "PETOSINBRAGITA":
        _name = "PETO";
        break;
      case "PETOSINBRAGILLACONELASTICOENPIERNAS":
        _name = "PETO CON ELASTICO EN PIERNA";
        break;
      default:
        _name = "PETO";
    }
  }

  if (name.includes("SHORT")) {
    switch (_name.replace(/\s/g, "")) {
      case "SHORTSINELASTICO":
        _name = "SHORT";
        break;
      case "SHORTSINELASTICOYTIRANTES":
        _name = "SHORT CON TIRANTES";
        break;
      case "SHORTTIRANTESUNIDOS":
        _name = "SHORT TIRANTES UNIDOS";
        break;
      default:
        _name = "SHORT";
    }
  }

  if (name.includes("FALDA")) {
    switch (_name.replace(/\s/g, "")) {
      case "FALDACONTIRANTE":
        _name = "FALDA CON TIRANTES";
        break;
      case "FALDASINTIRANTE":
        _name = "FALDA SIN TIRANTES";
        break;
      default:
        _name = name;
    }
  }
  return _name.toUpperCase();
};
