import { types } from "../types";

const initialState = {
    loading: false,
    msgError: null,
    showModal: false,
    policies: {
        reenvolso: `No hay devoluciones ni cambios. A no ser que sea un defecto completamente nuestro. Para ello tenemos las guías de tallas donde los clientes podrán ver y confirmar sus prendas seleccionadas.`,
        facturacion: `Facturas en casos excepcionales, se tendrá que poner en contacto con el administador.`,
    },
};

export const uiReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.uiSetError:
            return {
                ...state,
                msgError: payload,
            };
        case types.uiRemoveError:
            return {
                ...state,
                msgError: null,
            };
        case types.uiStartLoading:
            return {
                ...state,
                loading: true,
            };
        case types.uiFinishLoading:
            return {
                ...state,
                loading: false,
            };
        case types.uiShowModal:
            return {
                ...state,
                showModal: true,
            };
        case types.uiHideModal:
            return {
                ...state,
                showModal: false,
            };
        default:
            return state;
    }
};
