import { types } from "../types";
import { db } from "../../firebase";

import Swal from "sweetalert2";

import { payOrder } from "../../controllers/api_controller";
import { finishLoading, startLoading } from "../../redux/actions/ui";
import { orderRegisteredSwalConfigs } from "../../helpers/helpers";
import { getAllLinkItems, handleEmptyCart } from "./cart";
import { emptyCart, updateItems } from "../../controllers/cartcontroller";

const col = "orders";

export const updateFabricCosts = (items) => {
  return async (dispatch) => {
    try {
      //console.log("Actualizar estas telas", items);
      items.forEach(async (item) => {
        //console.log("Actualizar estas telas", item);

        let { Id_Tela, Costo_Tela, SKU } = item;
        if (SKU === "producto-personalizado-vestidos") {
          let fabric = await db.collection("fabrics").doc(Id_Tela).get();
          let { stock } = fabric.data();

          let _rest = parseInt(stock) - parseInt(Costo_Tela);

          //console.log("stock restante", _rest);
          await db.collection("fabrics").doc(Id_Tela).update({ stock: _rest });
        }
      });
    } catch (error) {
      console.log("Error al intentar actualizar el stock de telas", error);
    }
  };
};

export const handleRegisterOrder = (data) => {
  return async (dispatch) => {
    try {
      //console.log("data", data);
      let responsePay = await payOrder(data);
      //console.log("responsePay", responsePay);
      const { message, order } = responsePay.data;

      dispatch(finishLoading());

      if (message === "ok") {
        Swal.fire(orderRegisteredSwalConfigs).then(() => {
          dispatch(handleEmptyCart());
          window.location.href = `/confirmacion?order=${order}`;
        });
      } else {
        Swal.fire("", "Favor de revisar los datos bancarios ingresados.", "warning");
        dispatch(finishLoading());
      }
    } catch (error) {
      dispatch(finishLoading());
      console.log(error);
      Swal.fire("", "Favor de revisar los datos bancarios ingresados o informar al administrador.", "warning");
    }
  };
};

const getOrderPending = async (id, dispatch) => {
  try {
    //TODO: FALTA EL RELOAD DE LOS PRODUCTOS
    let orderPending = await db.collection("order_pendings").doc(id).get();

    if (orderPending.exists) {
      // obtener la orden pendiente
      let { order } = orderPending?.data();
      console.log("order obtenida", order);

      // vaciar el carrito
      emptyCart();

      // guardar nuevo carrito
      updateItems(order);
      dispatch(getAllLinkItems(order));
    } else {
      console.log("La orden pendiente no existe");
      Swal.fire("", "Orden no encontrada en el sistema, favor de informar al administrador.", "warning");
    }
  } catch (error) {
    console.log(error);
    Swal.fire("", "Error al obtener la orden guardada, favor de informar al administrador.", "warning");
  }
};

export const handleGetOrderPending = (id) => {
  return async (dispatch, getState) => {
    try {
      const {
        cart: { cartProducts },
      } = getState();

      console.log("id para obtener la orden", id, "items en el carrito", cartProducts);
      getOrderPending(id, dispatch);
    } catch (error) {
      dispatch(finishLoading());
      console.log(error);
      Swal.fire("", "Error al obtener la orden guardada, favor de informar al administrador.", "warning");
    }
  };
};

export const handleRegisterOrderPending = (data) => {
  return async (dispatch) => {
    try {
      Swal.fire({
        title: "Agregar un titulo para la orden",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Guardar",
        showLoaderOnConfirm: true,
        preConfirm: async (title) => {
          try {
            dispatch(startLoading());
            let response = await db.collection("order_pendings").add({ order: data, createdAt: new Date(), title });
            return response;
          } catch (error) {
            Swal.showValidationMessage(`
              Error al obtener url, favor de consultar al administrador: ${error}
            `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        console.log("result", result);

        if (result?.isConfirmed) {
          Swal.fire("Favor de copiar la url para compartir", `https://cucufate.mx/cart/${result?.value?.id}`, "info");
        }
        dispatch(finishLoading());
      });
    } catch (error) {
      console.log(error);
      Swal.fire("", "Error al registrar la orden, favor de intentar de nuevo.", "warning");
    }
  };
};

/**
 * @param {String} id ID del documento de firebase de la orden.
 * @description Se manda a llamar la orden en Firebase con el ID y se coloca en el selectedOrder, sino se pone en null.
 */
export const startGetSelectedOrderByID = (id) => {
  return async (dispatch) => {
    let response = await db.collection(col).doc(id).get();
    if (response.exists) {
      let orderData = response.data();
      orderData.ID = response.id;
      dispatch(selectOrder(orderData));
    } else {
      dispatch(selectOrder(null));
    }
  };
};

export const selectOrder = (order) => {
  return {
    type: types.selectOrder,
    payload: order,
  };
};

export const unselectOrder = () => {
  return {
    type: types.unselectOrder,
  };
};
