import React from "react";

import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Sizes from "../../sizes/Sizes";

export default function SizesModal({ showSizeModal, setShowSizeModal }) {
  const handleCloseModal = () => {
    setShowSizeModal(!showSizeModal);
  };

  return (
    <Modal isOpen={showSizeModal} toggle={handleCloseModal} size="lg" className="modalFabricsWizard">
      <ModalBody>
        <div className="container-fluid">
          <div className="form-row">
            <div className="col-12 col-lg-12 col-xl-12 my-3 fabricModalContent">
              <Sizes showPath={false} />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger mr-auto" onClick={handleCloseModal}>
          ACEPTAR
        </button>
      </ModalFooter>
    </Modal>
  );
}
