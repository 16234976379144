/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Sizes({ showPath }) {
  const [sizes] = useState([
    "CAMISAS.jpg",
    "CUBREPAÑAL.jpg",
    "FALDAS.jpg",
    "PELELES.jpg",
    "PETO BRAGUITA Y RANITA.jpg",
    "PETO.jpg",
    "SHORTS.jpg",
    "VESTIDOS.jpg",
    "PANTALON.jpg",
  ]);

  const [selected, setSelected] = useState("CAMISAS.jpg");

  const handleClick = (value) => {
    setSelected(value);
  };

  return (
    <>
      <div className="container-fluid title-search">
        <div className="container">
          <div className="row pt-5">
            {showPath && (
              <nav className="col-8" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/">
                      <a>INICIO</a>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active">TALLAS</li>
                </ol>
              </nav>
            )}
            <div className="col-12 col-md-8">
              <h1>Nuestras Tallas</h1>
            </div>
          </div>
        </div>
      </div>
      <div className={"container tallas-container animate__animated animate__fadeIn mt-4"}>
        <div className="row ">
          <div className="col-12 col-md-12">
            <div className="row">
              <h3>Seleccione una opción</h3>
              <div className="btn-group mr-2 btn-group-sm botones-tallas" role="group" aria-label="First group">
                {sizes.map((item, index) => (
                  <button key={index} type="button" className="btn btn-success" onClick={() => handleClick(item)}>
                    {item.replace(".jpg", "")}
                  </button>
                ))}
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-8">
                <div className="indicador-desliza text-center">&#10094; Deslice la tabla para verla completa &#10095;</div>
                <div className="card">
                  <img src={`assets/sizes/${selected}`} className="card-img-top" alt="..." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}
