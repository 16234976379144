import { types } from "../types";
import { addItem, emptyCart, getItems, removeItem, updateItems } from "../../controllers/cartcontroller";
import { db } from "../../firebase";

import Swal from "sweetalert2";

import { showModal } from "../../redux/actions/ui";
import { selectProduct } from "../../redux/actions/products";
import { deleteProductSwalConfigs } from "../../helpers/helpers";

export const getAllProducts = () => {
  return {
    type: types.getCartItems,
    payload: getItems(),
  };
};

export const getAllLinkItems = (items) => {
  return {
    type: types.getLinkItems,
    payload: items,
  };
};

export const addItemToCart = (product, redirect) => {
  //console.log("new product", product);
  return (dispatch) => {
    switch (addItem(product)) {
      case 200:
        //Swal.fire('', 'El artículo se agregó correctamente al carrito.', 'success');
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "El artículo se agregó correctamente al carrito.",
          showConfirmButton: false,
          timer: 2000,
        });

        if (redirect) {
          redirect();
        }

        dispatch(selectProduct(product));
        dispatch(showModal());
        dispatch(getAllProducts());

        break;
      case 405:
        Swal.fire("", "El artículo ya existe en el carrito.", "info");
        break;
      case 500:
        Swal.fire("", "Ocurrió un error al agregar el artículo al carrito.", "warning");
        break;
      default:
        break;
    }
  };
};

export const handleEmptyCart = () => {
  return (dispatch) => {
    emptyCart();
    dispatch(getAllProducts());
  };
};

export const handleUpdateCant = (productID, cant) => {
  return (dispatch) => {
    const product = getItems().filter((product) => product.ID === productID)[0];
    const newProductData = { ...product, Cant: cant };
    const newArray = [];
    getItems().forEach((item) => {
      newArray.push(item.ID === productID ? newProductData : item);
    });
    updateItems(newArray);
    dispatch(getAllProducts());
  };
};

export const deleteItemFromCart = (id, index) => {
  return (dispatch) => {
    Swal.fire(deleteProductSwalConfigs).then(async ({ value }) => {
      if (value) {
        removeItem(id, index);
        dispatch(getAllProducts());
      }
    });
  };
};

export const addFailedPayments = (error, data) => {
  return async (dispatch) => {
    try {
      let log = {
        error,
        data,
        createdAt: new Date(),
      };
      await db.collection("failed_payments").add(log);
    } catch (error) {
      console.log("Error al insertar el log", error);
    }
  };
};
