import { types } from "../types";

const initialState = {
    selectedOrder: undefined,
};

export const ordersReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.selectOrder:
            return {
                ...state,
                selectedOrder: payload
            }
        case types.unselectOrder:
            return {
                ...state,
                selectedOrder: undefined
            }
        default:
            return state;
    }
};
