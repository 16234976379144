import { useRef } from "react";

import React, { useState } from "react";
import { Wizard } from "react-use-wizard";

import "font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../utils/Header";
import MetaHelmet from "../metahelmet";

import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";

import styles from "../home/Home.module.scss";
import "./wizard.scss";

export default function Index() {
  const myRef = useRef(null);

  const [metadata] = useState({
    title: "Cucufate MX | Arma tu prenda",
    description: "En CucufateMX te permitimos que armes tu propia prenda con los detalles a tu comodidad.",
    image: "https://cucufate.s3.us-west-2.amazonaws.com/logo.png",
    url: "https://cucufate.mx/wizard",
  });

  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <div className="wizard">
      <MetaHelmet data={metadata}></MetaHelmet>
      <Header />
      <div className={"slide container-fluid animate__animated animate__fadeIn "}>
        <img src="assets/slide/slide-content.jpg" alt="cucufate" className="w-100" />
      </div>
      <div className={`container animate__animated animate__fadeIn ${styles.welcome}`}>
        <div className="row">
          <div className="col-12">
            <span id="ancla" ref={myRef}></span>
            <h1 className="spacing-mb-32">Arma tú propia prenda.</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Wizard>
              <Step1 executeScroll={executeScroll} />
              <Step2 executeScroll={executeScroll} />
              <Step3 executeScroll={executeScroll} />
              <Step4 executeScroll={executeScroll} />
            </Wizard>
          </div>
        </div>
      </div>
    </div>
  );
}
