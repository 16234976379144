import { types } from "../types";

const initialState = {
    validCoupon: false,
    couponAdded: null,
    messageCoupon: "",
};

export const couponReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case types.verifyCoupon:
            return {
                ...state,
                validCoupon: payload.validCoupon,
                couponAdded: payload.coupon,
            };
        case types.updateCouponMessage:
            return {
                ...state,
                messageCoupon: payload,
            };
        default:
            return state;
    }
};
