import React from "react";
import classNames from "classnames";
import "./cardinfo.scss";

export const Cardinfo = ({ children, color, className }) => {
  const cardInfoClasses = {
    cardinfo: true,
    [`cardinfo-${color}`]: color,
    [`${className}`]: className,
  };
  return <div className={classNames(cardInfoClasses)}>{children}</div>;
};
