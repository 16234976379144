/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import { hideModal } from "../../redux/actions/ui";
import { Button } from "./ui/Button";
import { getImages } from "../../helpers/images";
import Details from "../wizard/aditionals/Details";
import { selectProduct } from "../../redux/actions/products";
import { addItemToCart } from "../../redux/actions/cart";

export default function ProductModal({ dontUnselect, wizard = false }) {
    const dispatch = useDispatch();
    const { selectedProduct } = useSelector((state) => state.products);
    const { showModal } = useSelector((state) => state.ui);

    const [selectedTallas, setSelectedTallas] = useState([]);

    const handleStatusChange = (selected) => {
        const tallas = [selected].map((option) => option.value); // Envolver en un arreglo y obtener el valor de la opción seleccionada
        setSelectedTallas(tallas);
    };
    const handleGoToCart = () => {
        window.location.href = "/cart";
    };

    const handleGoToShop = () => {
        window.location.href = "/shop";
    };

    const handleAddToCart = () => {
        if (selectedProduct && selectedProduct.EAN) {
            const updatedProduct = {
                ...selectedProduct,
                Talla: selectedTallas[0],
            };
            dispatch(addItemToCart(updatedProduct, handleGoToShop));

            if (wizard) {
                window.location.href = "/wizard";
            }

            dispatch(selectProduct(updatedProduct));
        } else {
            console.error("No se ha seleccionado ningún producto válido");
        }
        handleCloseModal();
        //   handleGoToShop();
    };

    const handleCloseModal = () => {
        if (wizard) {
            window.location.href = "/wizard";
        }
        dispatch(hideModal());
    };

    const tallasOptions = selectedProduct?.Tallas
        ? selectedProduct.Tallas.map((talla) => ({
              value: talla,
              label: talla,
          }))
        : [];
    const primerTalla = tallasOptions && tallasOptions.length > 0 ? tallasOptions[0] : null;

    return (
        <Modal isOpen={showModal} toggle={handleCloseModal} className="modal-product-added" size="lg">
            <ModalHeader toggle={handleCloseModal}>
                <h5 className="">
                    Añadir producto al carrito <i className="fa fa-thumbs-up"></i>
                </h5>
            </ModalHeader>
            <ModalBody>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            {selectedProduct?.EAN === "producto-personalizado" ? (
                                <>
                                    <img
                                        className="img-fluid selected-style"
                                        src={getImages()[selectedProduct.Images.part1](`./${selectedProduct.Images.part2}`)}
                                        alt={`${selectedProduct.Images.part2}`}
                                    />
                                </>
                            ) : (
                                <img className="card-img-top catalog-img fadeInUp" src={selectedProduct.Imagen_Principal} alt="" />
                            )}
                        </div>
                        <div className="col-md-8 text-left">
                            {selectedProduct.EAN !== "producto-personalizado" && (
                                <>
                                    <h4 className="spacing-mb-8">{selectedProduct.Nombre_del_Producto}</h4>
                                    <p className="body-regular">{selectedProduct.Descripcion_Corta}</p>

                                    <div className="col-12 col-lg-6 col-xl-6 my-3">
                                        <label>
                                            Seleccione su talla por favor: <span className="text-red">*</span>
                                        </label>
                                        <Select
                                            options={tallasOptions}
                                            value={selectedTallas.length > 0 ? tallasOptions.find((option) => option.value === selectedTallas[0]) : primerTalla}
                                            onChange={handleStatusChange}
                                            required
                                        />
                                    </div>
                                </>
                            )}

                            {selectedProduct.EAN === "producto-personalizado" && <Details showTitle={false} />}
                        </div>
                        <div className="col-md-8"></div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-between flex-nowrap">
                <div className="d-flex justify-content-between gap-2">
                    <Button type="button" color="red" theme="outlined" size="small" onClick={handleCloseModal}>
                        <br className="d-inline d-lg-none" /> CANCELAR
                    </Button>
                    {selectedProduct.EAN !== "producto-personalizado" ? (
                        <Button type="button" size="small" onClick={handleAddToCart}>
                            <i className="fa fa-shopping-cart"></i> &nbsp;
                            <br className="d-inline d-lg-none" /> AÑADIR
                        </Button>
                    ) : null}
                </div>
            </ModalFooter>
        </Modal>
    );
}
