import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { deleteItemFromCart, handleUpdateCant } from "../../redux/actions/cart";
import { Button } from "reactstrap";
import { getImages } from "../../helpers/images";
import { validateName } from "../../helpers/cleanParams";

export default function ItemRow(props) {
  const dispatch = useDispatch();

  const { item, index } = props;
  const { Personalizado } = item;

  const [sleeves] = useState(getImages());

  const handleChangeInput = ({ target }) => {
    const { value } = target;
    if (value > 0) {
      dispatch(handleUpdateCant(item.ID, parseInt(value)));
    }
  };

  const handleDeleteItem = (id) => {
    dispatch(deleteItemFromCart(id, index));
  };

  const DiscountView = ({ hasDiscount, price, price_discount }) => {
    return hasDiscount ? (
      <>
        {" "}
        <small>
          <del>$ {price}</del>
        </small>
        <br /> $ <b>{price_discount}</b>{" "}
      </>
    ) : (
      <>
        {" "}
        $ <b>{price}</b>{" "}
      </>
    );
  };

  return (
    <tr className="table-cells-cart">
      <td className="text-left">{index + 1}</td>

      <td className="text-left">
        {item.EAN === "producto-personalizado" ? (
          <>
            <img
              className="fadeInUp img-product"
              src={sleeves[item.Images.part1](`./${item.Images.part2}`)}
              alt={`${item.Images.part2}`}
              fit-mode="auto"
            />
            {Personalizado !== undefined &&
              Personalizado !== null &&
              Personalizado?.telaSeleccionada !== null &&
              Personalizado?.telaSeleccionada !== undefined && (
                <img className="fadeInUp img-product pt-3" src={Personalizado.telaSeleccionada} alt={`${Personalizado.tela}}`} />
              )}

            {Personalizado?.tipo === "VESTIDO_CRUZADO" && (
              <img className="fadeInUp img-product pt-3" src={Personalizado.telaSeleccionada2} alt={`${Personalizado.tela2}}`} />
            )}
          </>
        ) : (
          <img className="fadeInUp img-product" src={item.Imagen_Principal} alt="producto" fit-mode="auto" />
        )}
      </td>
      <td className="text-left">
        {item.EAN === "producto-personalizado" && Personalizado !== undefined && Personalizado !== null && (
          <>
            <ul className="details-text mt-1">
              <li className="">
                <span>Prenda:</span> {Personalizado.prenda.replace(/_/g, " ").toLowerCase()}
              </li>

              {Personalizado.tipo !== "" && (
                <li className="">
                  <span>Tipo:</span> {validateName(Personalizado.tipo.replace(/_/g, " ")).toLowerCase()}
                </li>
              )}

              {Personalizado.cuello !== "" && (
                <li className="">
                  <span>Cuello:</span> {Personalizado.cuello.replace(/_/g, " ").toLowerCase()}
                </li>
              )}

              {Personalizado.manga !== "" && (
                <li className="">
                  <span>Manga:</span> {Personalizado.manga.toLowerCase()}
                </li>
              )}

              {Personalizado.tela !== "" && (
                <li className="">
                  <span>Tela:</span> {Personalizado.tela.replace(/_/g, " ").toLowerCase()}
                </li>
              )}

              {Personalizado?.tipo === "VESTIDO_CRUZADO" && (
                <li className="">
                  <span>Tela Olan:</span> {Personalizado.tela2.replace(/_/g, " ").toLowerCase()}
                </li>
              )}

              {Personalizado.tallaLabel !== "" && (
                <li className="">
                  <span>Talla:</span> {Personalizado.tallaLabel.toLowerCase()}
                </li>
              )}

              {(Personalizado.prenda === "PELELE" || Personalizado.prenda === "VESTIDOS") && (
                <li className="">
                  <span>Opción:</span> {Personalizado.fruncido.replace(/_/g, " ").toLowerCase()}
                </li>
              )}

              {Personalizado.prenda === "PORTACHUPON" && (
                <li className="">
                  <span>Opción:</span> {Personalizado.personalizado.replace(/_/g, " ").toLowerCase()}
                </li>
              )}
            </ul>
          </>
        )}

        {item?.EAN !== "producto-personalizado" && (
          <>
            <ul className="details-text mt-1">
              <li className="">
                <span>Prenda:</span> {item?.Nombre_del_Producto?.replace(/_/g, " ").toLowerCase()}
              </li>
              {item?.Talla !== "" && (
                <li className="">
                  <span>Talla:</span> {item?.Talla?.toLowerCase()}
                </li>
              )}
            </ul>
          </>
        )}
      </td>
      <td width="70">
        <input name="quantity" type="number" className="form-control text-right" value={item.Cant} onChange={handleChangeInput} />
      </td>
      <td width="100" className="text-center">
        <DiscountView hasDiscount={item.Precio_Oferta !== "-"} price={item.Precio} price_discount={item.Precio_Oferta} />
      </td>
      <td className="text-center">
        <b>{item.Precio_Oferta !== "-" ? "$" + item.Precio_Oferta * item.Cant : "$" + item.Precio * item.Cant}</b>
      </td>
      <td className="text-center px-0" width="100">
        <Button color="danger" size="sm" onClick={() => handleDeleteItem(item.ID)}>
          <FontAwesomeIcon icon={faTrashAlt} />
          &nbsp;&nbsp; Borrar
        </Button>
      </td>
    </tr>
  );
}
