import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { authReducer } from "../reducers/authReducer";
import { cartReducer } from "../reducers/cartReducer";
import { locationReducer } from "../reducers/locationReducer";
import { ordersReducer } from "../reducers/ordersReducer";
import { productsReducer } from "../reducers/productsReducer";
import { uiReducer } from "../reducers/uiReducer";
import { couponReducer } from "../reducers/couponReducer";
import { wizardReducer } from "../reducers/wizardReducer";
import { contactReducer } from "../reducers/contactReducer";
import { ideasReducer } from "../reducers/ideasReducer";
import { fabricCategoriesReducer } from "../reducers/fabricCategoriesReducer";
import { homeReducer } from "../reducers/homeReducer";
import { configsReducer } from "../reducers/configsReducer";

const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  products: productsReducer,
  configs: configsReducer,
  cart: cartReducer,
  orders: ordersReducer,
  location: locationReducer,
  coupons: couponReducer,
  wizard: wizardReducer,
  contact: contactReducer,
  ideas: ideasReducer,
  home: homeReducer,
  fabricCategories: fabricCategoriesReducer,
});

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
